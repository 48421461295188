import { requestWithToken } from "../helper/helper";

// Fetch all event conversations
export const SendEmails = async (payload) => {
    try {
      const response = await requestWithToken("POST", "/api/send-email/",payload);
      return response.data;
    } catch (err) {
      console.error("Error fetching event conversations:", err);
      return err.response?.data || { message: err.message };
    }
  };