// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatsapp-chat-page {
  width: 100%;
  height: 100vh;
  background-color: #eae6df;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
} `, "",{"version":3,"sources":["webpack://./src/pages/conversation/WhatsAppChat.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf","sourcesContent":[".whatsapp-chat-page {\r\n  width: 100%;\r\n  height: 100vh;\r\n  background-color: #eae6df;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 20px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
