import React from "react";
import squar from "../../assets/images/squar.svg";
import addBrown from "../../assets/images/add-brown.svg";

const CelebrationForm = ({
  addEventFormik,
  addMoreFamilyMembers,
  deleteFamilyMembers,
}) => {
  const handleTextInput = (event) => {
    const { name, value } = event.target;
    addEventFormik.setFieldValue(name, value);
  };

  // Ensure celebrationObj exists with default values
  const celebrationObj = addEventFormik.values.celebrationObj || {
    host_name: "",
    celebration_type: "",
    is_single_event: false,
    event_details: ""
  };

  return (
    <div className="row">
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Celebration Details</span>
      </h2>
      <div className="col-lg-4">
        <div className="input-box">
          <div className={`input-label ${addEventFormik.errors?.celebrationObj?.celebration_type ? 'error' : ''}`}>
            Celebration Type
          </div>
          <div className="input_field">
            <input
              name="celebrationObj.celebration_type"
              value={celebrationObj.celebration_type || ""}
              onChange={handleTextInput}
              onBlur={addEventFormik.handleBlur}
              type="text"
              className={`input ${addEventFormik.touched?.celebrationObj?.celebration_type && addEventFormik.errors?.celebrationObj?.celebration_type ? 'input-error' : ''}`}
              placeholder="Enter celebration type"
            />
          </div>
          {addEventFormik.touched?.celebrationObj?.celebration_type && addEventFormik.errors?.celebrationObj?.celebration_type && (
            <p className="error">{addEventFormik.errors.celebrationObj.celebration_type}</p>
          )}
        </div>
      </div>

      <div className="col-lg-4">
        <div className="input-box">
          <div className={`input-label ${addEventFormik.errors?.celebrationObj?.host_name ? 'error' : ''}`}>
            Host Name
          </div>
          <div className="input_field">
            <input
              name="celebrationObj.host_name"
              value={celebrationObj.host_name || ""}
              onChange={handleTextInput}
              onBlur={addEventFormik.handleBlur}
              type="text"
              className={`input ${addEventFormik.touched?.celebrationObj?.host_name && addEventFormik.errors?.celebrationObj?.host_name ? 'input-error' : ''}`}
              placeholder="Enter host name"
            />
          </div>
          {addEventFormik.touched?.celebrationObj?.host_name && addEventFormik.errors?.celebrationObj?.host_name && (
            <p className="error">{addEventFormik.errors.celebrationObj.host_name}</p>
          )}
        </div>
      </div>

      <div className="col-lg-4">
        <div className="input-box">
          <label className="mt-lg-4">
            <input
              className="me-2"
              name="celebrationObj.is_single_event"
              type="checkbox"
              checked={celebrationObj.is_single_event}
              onChange={(e) => {
                addEventFormik.setFieldValue("celebrationObj.is_single_event", e.target.checked);
              }}
            />
            Single Event
          </label>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="input-box">
          <div className={`input-label ${addEventFormik.errors?.celebrationObj?.event_details ? 'error' : ''}`}>
            Event Description
          </div>
          <div className="input_field">
            <textarea
              name="celebrationObj.event_details"
              value={celebrationObj.event_details || ""}
              onChange={handleTextInput}
              onBlur={addEventFormik.handleBlur}
              rows={6}
              className={`input ${addEventFormik.touched?.celebrationObj?.event_details && addEventFormik.errors?.celebrationObj?.event_details ? 'input-error' : ''}`}
              placeholder="Enter event description"
            />
          </div>
          {addEventFormik.touched?.celebrationObj?.event_details && addEventFormik.errors?.celebrationObj?.event_details && (
            <p className="error">{addEventFormik.errors.celebrationObj.event_details}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CelebrationForm;
