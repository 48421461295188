/* global FB */

import { Link, useLocation, useNavigate } from "react-router-dom";
import showNotification from "./notification/notification";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import MyModal from "./model";
import SelectFileDragDropFile from "./selectFileDragDrop";
import { fetchActiveEvents } from "../action/events";
import React from "react";

// Images
import  "../styles/style.css";
import LogoIcon from "../assets/images/logo-icon.png";
import LogoText from "../assets/images/logo-text.png";
import plusIcon from "../assets/images/plus.svg";
import homeIcon from "../assets/images/home.svg";
import eventIcon from "../assets/images/event.svg";
import settingIcon from "../assets/images/settings.svg";
import FacebookIcon from "../assets/images/facebook.png";
import PrivacyIcon from "../assets/images/privacy-policy.png";
import BotIcon from "../assets/images/boticon.png";
import logout from "../assets/images/logout_icon.png";
import ProfileIcon from "../assets/images/logo-icon.png"; // Added profile icon
import botIcon from "../assets/images/bot.svg";

export function SideNav({ onClick }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector((state) => state.userDetails.value);

  const [showEventAddModal, setShowEventAddModal] = useState(false);
  const [activeRoute, setActiveRoute] = useState(1);
  const [activeEvents, setActiveEvents] = useState([]);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setActiveRoute(1);
        break;
      case "/events":
        setActiveRoute(2);
        break;
      case "/privacy-policy":
        setActiveRoute(3);
        break;
      case "/profile":
        setActiveRoute(4);
        break;
      case "/settings":
        setActiveRoute(5);
        break;
      case "/chating":
        setActiveRoute(6);
        break;
   
      default:
        setActiveRoute(0);
        break;
    }
  }, [location.pathname]);

  const logoutUser = () => {
    showNotification("Logout successfully", "success");
    localStorage.clear();
    navigate("/login");
  };

  const loadFacebookSDK = () => {
    if (!window.FB) {
      const script = document.createElement('script');
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      script.onload = () => {
        window.fbAsyncInit = function () {
          FB.init({
            appId: '1030288362049862',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v20.0'
          });
          console.log("Facebook SDK initialized");
        };
      };
      document.body.appendChild(script);
    }
  };
  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      console.log("🚀 ~ fbLoginCallback ~ code:", code);
 
    document.getElementById("sdk-response").textContent =
     JSON.stringify(
      response,
      null,
      2
    );  
   }
  
  };
  const facebook = () => {
    const handleFBLogin = () => {
      loadFacebookSDK(); // Ensure SDK is loaded and initialized
      if (window.FB) {
        window.FB.login(function (response) {
          if (response.authResponse) {
            console.log('User is logged in, Access Token:', response.authResponse.accessToken);
            showNotification("Logged in successfully with Facebook", "success");
          } else {
            console.log('User cancelled login or did not fully authorize.');
            showNotification("Facebook login cancelled", "warning");
          }
        }, { scope: 'public_profile,email' });
      } else {
        console.error("Facebook SDK not loaded");
        showNotification("Failed to load Facebook SDK", "danger");
      }
    };
    handleFBLogin();

  }
  const getActiveEvents = async () => {
    const res = await fetchActiveEvents("", 1, 3, "");
    if (res.status) {
      setActiveEvents(res.data.objects);
    }
  };

  useEffect(() => {
    getActiveEvents();
  }, []);

  return (
    <>
      <div className="side-menu">
        <div className="logoblock">
          <img className="logoicon" src={LogoIcon} alt="Logo" title="logo" />
          <span className="show-on-xpand logotext">
            <img src={LogoText} alt="Logo" title="logo" />
          </span>
        </div>

        <Link className="addblock" to="/add-event">
          <img className="plusicon" src={plusIcon} alt="Plus icon" />
          <span className="show-on-xpand"> Add New Event</span>
        </Link>

        <div className="menublock">
          <ul className="menuitems">
            <li className={`${activeRoute === 1 ? "active" : ""}`}>
              <Link to={"/"}>
                <img alt="Home icon" src={homeIcon} className="menu-icon" />
                <span className="show-on-xpand">Dashboard</span>
              </Link>
            </li>
            <li className={`${activeRoute === 2 ? "active" : ""}`}>
              <Link to={"/events"}>
                <img alt="Events icon" src={eventIcon} className="menu-icon" />
                <span className="show-on-xpand">Events</span>
              </Link>
            </li>
            <li className={`${activeRoute === 5 ? "active" : ""}`}>
              <Link to="/settings">
                <img src={settingIcon} className="menu-icon" alt="Settings icon" />
                <span className="show-on-xpand">Settings</span>
              </Link>
            </li>
            <li className={`${activeRoute === 3 ? "active" : ""}`}>
              <Link to={"/privacy-policy"}>
                <img src={PrivacyIcon} className="menu-icon" alt="Privacy policy icon" />
                <span className="show-on-xpand">Privacy policy</span>
              </Link>
            </li>
            <li className={`${activeRoute === 4 ? "active" : ""}`}>
              <Link to={"/profile"}>
                <img src={ProfileIcon} className="menu-icon" alt="Profile icon" /> 
                <span className="show-on-xpand">Profile</span> 
              </Link>
            </li>
            <li className={`${activeRoute === 6 ? "active" : ""}`}>
              <Link to={"/chating"}>
                <img src={botIcon} className="menu-icon" alt="AI Assistant" />
                <span className="show-on-xpand">Ask AI</span>
              </Link>
            </li>
           
            <li>
              <a onClick={logoutUser}>
                <img src={logout} className="menu-icon" alt="Logout icon" />
                <span className="show-on-xpand">Logout</span>
              </a>
            </li>

          </ul>
        </div>
      </div>

      <MyModal
        label={"Create an Event"}
        titleclass={"title-heading1"}
        show={showEventAddModal}
        handleClose={() => setShowEventAddModal(false)}
      >
        <div className="blck1">
          <div className="inputGrp">
            <label className="theme-leble" htmlFor="">
              Select Event Type
            </label>
            <Form.Select aria-label="Select Event Type">
              <option>Select Event Type</option>
              <option value="1">Wedding</option>
              <option value="2">Pre-Wedding</option>
              <option value="3">Birth Day</option>
            </Form.Select>
          </div>
        </div>
        <SelectFileDragDropFile
          loader={false}
          onFileSelect={(file) => {
            const image = file;
            if (image) {
              if (image.name.match(/\.(jpg|jpeg|png|gif|PNG)$/)) {
                console.log("Selected file:", file);
              } else {
                showNotification("Please Select an Image", "danger");
              }
            }
          }}
        />
        <div className="orr">or</div>
        <div className="bbtn">
          <Link
            to="/add-event"
            className="btn-theme bg-dark"
            onClick={() => setShowEventAddModal(false)}
          >
            Create using form
          </Link>
        </div>
      </MyModal>
    </>
  );
}

export default SideNav;
