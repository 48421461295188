import { requestWithToken } from "../helper/helper";

// Fetch all templates with optional filters
export const fetchTemplates = (filters = {}) => {
  const queryParams = new URLSearchParams();
  if (filters.search) queryParams.append('search', filters.search);
  if (filters.language) queryParams.append('language', filters.language);
  if (filters.isActive !== undefined) queryParams.append('is_active', filters.isActive);
  if (filters.ordering) queryParams.append('ordering', filters.ordering);
  
  const url = `/api/templates/${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
  return requestWithToken("GET", url)
    .catch(error => {
      console.error('Error fetching templates:', error);
      throw error;
    });
};

// Create new template
export const createTemplate = (templateData) => {
  return requestWithToken("POST", "/api/templates/", templateData)
    .catch(error => {
      console.error('Error creating template:', error);
      throw error;
    });
};

// Update existing template
export const updateTemplate = (templateId, templateData) => {
  return requestWithToken("PUT", `/api/templates/${templateId}/`, templateData)
    .catch(error => {
      console.error('Error updating template:', error);
      throw error;
    });
};

// Toggle template active status
export const toggleTemplateActive = (templateId) => {
  return requestWithToken("POST", `/api/templates/${templateId}/toggle_active/`)
    .catch(error => {
      console.error('Error toggling template status:', error);
      throw error;
    });
};

// Send template message
export const sendTemplateMessage = (conversationId, templateName, parameters, eventId) => {
  return requestWithToken("POST", "/api/template/", {
    conversation_id: conversationId,
    type: 'template',
    template_name: templateName,
    template_parameters: parameters,
    event: eventId
  })
  .catch(error => {
    console.error('Error sending template message:', error);
    throw error;
  });
};