// /api.js
import { requestWithToken } from "../helper/helper.js";

// Fetch all event conversations
export const fetchEventConversations = async () => {
  try {
    const response = await requestWithToken("GET", "/api/event-conversations/");
    return response.data;
  } catch (err) {
    console.error("Error fetching event conversations:", err);
    return err.response?.data || { message: err.message };
  }
};

// Retrieve a specific event conversation
export const getEventConversation = async (conversationId) => {
  try {
    const response = await requestWithToken("GET", `api/event-conversation/${conversationId}/`);
    return response.data;
  } catch (err) {
    console.error("Error retrieving event conversation:", err);
    return err.response?.data || { message: err.message };
  }
};
export const getEventConversationByEventAndGuest = async (event_id, guest_id) => {
  try {
    const url = `api/event-conversations/get_conversation_by_event_and_guest/`;
    const response = await requestWithToken("POST", url, {
      event_id: event_id,
      guest_id: guest_id
    });

    if (!response.data) {
      throw new Error('No response data received');
    }

    return response.data;
  } catch (error) {
    console.error("Error retrieving event conversation:", error);
    return {
      status: "error",
      message: error.response?.data?.message || error.message
    };
  }
};



// Create a new event conversation
export const createEventConversation = async (payload) => {
  try {
    const response = await requestWithToken("POST", "api/event-conversations/", {
      event: payload.event,
      guest: payload.guest
    });

    if (!response?.data) {
      throw new Error('No response received from server');
    }

    if (response.data.status === 'error') {
      throw new Error(response.data.message);
    }

    return {
      status: 'success',
      conversation: response.data.conversation
    };
  } catch (error) {
    console.error("Error creating conversation:", error);
    return {
      status: 'error',
      message: error.message || 'Failed to create conversation'
    };
  }
};

// Fetch all messages for a conversation
export const fetchMessages = async (conversationId) => {
  try {
    const response = await requestWithToken("GET", `/api/messages/?conversation=${conversationId}`);
    
    if (!response?.data) {
      throw new Error('No messages data received');
    }

    // Transform the messages data
    return response.data.map(msg => ({
      id: msg.id,
      message: msg.message,
      timestamp: msg.created_at || msg.timestamp,
      guest: msg.message_type === 'inbound',
      type: msg.message_type,
      message_sid: msg.message_sid
    }));

  } catch (error) {
    console.error("Error fetching messages:", error);
    throw error;
  }
};

// Send a message in a conversation
export const sendMessage = async (payload) => {
  try {
    if (!payload.conversation_id || !payload.message_text || !payload.event) {
      throw new Error('Missing required parameters');
    }

    const response = await requestWithToken("POST", "api/whatsapp/send/", {
      conversation_id: payload.conversation_id,
      message_text: payload.message_text,
      event: payload.event,
      guest: payload.guest
    });

    if (!response?.data) {
      throw new Error('No response received from server');
    }

    // Transform the response to match the message format
    const { message } = response.data;
    return {
      status: 'success',
      message_sid: response.data.message_sid,
      message: {
        id: message.id,
        message: message.message,
        timestamp: message.timestamp,
        guest: null,
        type: message.type,
        message_sid: message.message_sid
      }
    };
  } catch (error) {
    console.error("Error sending message:", error);
    return {
      status: 'error',
      message: error.message || 'Failed to send message'
    };
  }
};

export const fetchGuests = async (eventId) => {
  try {
    const response = await requestWithToken("GET", `api/events/${eventId}/allguests/`);
    return response.data;
  } catch (err) {
    console.error("Error fetching guests:", err);
    return err.response?.data || { message: err.message };
  }
};

export const receiveWhatsAppMessage = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/api/whatsapp/receive/", payload);
    return response.data;
  } catch (err) {
    console.error("Error receiving WhatsApp message:", err);
    return err.response?.data || { message: err.message };
  }
};

export const showGuest = async (guest_id) => {
  try {
    const response = await requestWithToken("GET", `/api/guests/${guest_id}/show-guest/`);
    return response.data;
  } catch (err) {
    console.error("Error receiving WhatsApp message:", err);
    return err.response?.data || { message: err.message };
  }
};

export const api = {
  fetchEventConversations,
  getEventConversation,
  createEventConversation,
  fetchMessages,
  sendMessage,
  fetchGuests,
  receiveWhatsAppMessage,
  showGuest,
  getEventConversationByEventAndGuest
};
