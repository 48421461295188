import React from "react";
import moment from "moment";
import defaultEventIcon from "../../assets/images/eventimg1.jpg";
import starIcon from "../../assets/images/star.svg";
import guestIcon from "../../assets/images/guest.svg";
import logisticIcon from "../../assets/images/logis.svg";
import acomoIcon from "../../assets/images/acomo.svg";
import communicationIcon from "../../assets/images/communication.svg";
import conversationIcon from "../../assets/images/chat.svg";
import calendarIcon from "../../assets/images/calendor.svg";
import locationIcon from "../../assets/images/location.svg";
import profile from "../../assets/images/profile.svg";
import { Link } from "react-router-dom";

const ActiveEventCard = ({ value, colClass = "col-md-9" }) => {
  const getEventImage = (eventType) => {
    switch(eventType) {
      case 'Wedding':
        return 'https://images.unsplash.com/photo-1519741497674-611481863552?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80';
      case 'PreWedding':
        return 'https://images.unsplash.com/photo-1583939003579-730e3918a45a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80';
      case 'Celebration':
        return 'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80';
      default:
        return null;
    }
  };

  const renderDefaultIcon = () => {
    return (
      <div 
        style={{
          width: '200px',
          height: '200px',
          backgroundColor: '#f8f9fa',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px',
          padding: '40px'
        }}
      >
        <img 
          src={profile} 
          alt="Default Event"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            opacity: '0.7'
          }}
        />
      </div>
    );
  };

  const renderEventImage = () => {
    const eventImage = getEventImage(value.event_type);
    if (eventImage) {
      return (
        <img 
          src={eventImage} 
          className="feimg" 
          alt={`${value.event_type} Event`}
          onError={(e) => {
            e.target.style.display = 'none';
            e.target.parentElement.appendChild(
              renderDefaultIcon().props.children
            );
          }}
        />
      );
    }
    return renderDefaultIcon();
  };

  return (
    <div className={colClass}>
      <div className="card-custom card2">
        <Link to={`/event-details/${value.id}`} className="card-link">
          <div className="row g-0">
            <div className="col-md-8">
              <div className="card2-leftbox">
                <div className="card2-infobx-left">
                  {renderEventImage()}
                </div>
                <div className="card2-infobx-right">
                  <div className="headingbx">
                    <div className="event-type">{value.event_type}</div>
                    <h3 className="event-name">{value.name}</h3>
                  </div>
                  <ul className="event-details-list">
                    <li>
                      <span className="icon-wrapper">
                        <img src={calendarIcon} alt="Calendar" />
                      </span>
                      <p>{moment(value.date).format("DD MMM YYYY")}</p>
                    </li>
                    <li>
                      <span className="icon-wrapper">
                        <img src={locationIcon} alt="Location" />
                      </span>
                      <p>{value.location}</p>
                    </li>
                    <li>
                      <span className="icon-wrapper">
                        <img src={starIcon} alt="Venue" />
                      </span>
                      <p>{value.venue}</p>
                    </li>
                    {value?.event_object?.sub_events?.length > 0 && (
                      <li>
                        <span className="icon-wrapper">
                          <img src={starIcon} alt="Events" />
                        </span>
                        <p className="sub-events">
                          {value?.event_object?.sub_events
                            ?.slice(0, 2)
                            ?.map((subEvent, index) => (
                              <span key={index} className="sub-event-name">
                                {subEvent.name}{index !== 1 && value?.event_object?.sub_events.length > 1 ? ", " : ""}
                              </span>
                            ))}
                          {value?.event_object?.sub_events?.length > 2 && (
                            <span className="more-events">
                              +{value?.event_object?.sub_events?.length - 2} More
                            </span>
                          )}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <div className="feature-box">
                <ul className="feature-list">
                  <li>
                    <Link
                      to={`/guests/${value?.id}/${value?.company}`}
                      className="feature-link"
                    >
                      <span className="icon-wrapper">
                        <img src={guestIcon} alt="Guest Management" />
                      </span>
                      <span>Guest Management</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/logistics/${value?.id}`} className="feature-link">
                      <span className="icon-wrapper">
                        <img src={logisticIcon} alt="Logistics" />
                      </span>
                      <span>Logistics</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/hotels/${value?.id}`} className="feature-link">
                      <span className="icon-wrapper">
                        <img src={acomoIcon} alt="Accommodation" />
                      </span>
                      <span>Accommodation</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/communication/${value?.id}`} className="feature-link">
                      <span className="icon-wrapper">
                        <img src={communicationIcon} alt="Communication" />
                      </span>
                      <span>Communication</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/conversation/${value?.id}`} className="feature-link">
                      <span className="icon-wrapper">
                        <img src={conversationIcon} alt="Conversation" />
                      </span>
                      <span>Conversation</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <style jsx>{`
        .card-custom {
          padding: 20px;
          background: #fff;
          border-radius: 12px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;
        }

        .card-link {
          text-decoration: none;
          color: inherit;
        }

        .card2-leftbox {
          display: flex;
          gap: 20px;
          padding: 15px;
        }

        .card2-infobx-left .feimg {
          width: 200px;
          height: 200px;
          border-radius: 10px;
          object-fit: cover;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          transition: transform 0.3s ease;
        }

        .card2-infobx-left .feimg:hover {
          transform: scale(1.05);
          box-shadow: 0 4px 8px rgba(207, 181, 59, 0.3);
        }

        .event-type {
          font-size: 14px;
          color: #666;
          margin-bottom: 5px;
          text-transform: uppercase;
          letter-spacing: 1px;
          background: #f8f9fa;
          padding: 4px 12px;
          border-radius: 20px;
          display: inline-block;
        }

        .event-name {
          font-size: 28px;
          color: #333;
          margin: 10px 0 20px;
          font-weight: 600;
          line-height: 1.2;
        }

        .event-details-list {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .event-details-list li {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          color: #555;
          font-size: 15px;
        }

        .icon-wrapper {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          background: #f8f9fa;
          border-radius: 8px;
          transition: all 0.3s ease;
        }

        .icon-wrapper img {
          width: 18px;
          height: 18px;
        }

        .sub-event-name {
          color: #666;
        }

        .more-events {
          background: #f0f0f0;
          padding: 2px 8px;
          border-radius: 12px;
          font-size: 12px;
          color: #666;
          margin-left: 5px;
        }

        .feature-box {
          background: #f8f9fa;
          padding: 20px;
          border-radius: 10px;
          width: 100%;
          box-shadow: inset 0 2px 4px rgba(0,0,0,0.05);
        }

        .feature-list {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .feature-list li {
          margin-bottom: 10px;
        }

        .feature-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #555;
          padding: 10px 15px;
          border-radius: 8px;
          transition: all 0.3s ease;
          background: white;
        }

        .feature-link:hover {
          background: #CFB53B;
          color: white;
          transform: translateX(5px);
        }

        .feature-link:hover .icon-wrapper {
          background: rgba(255,255,255,0.2);
        }

        @media (max-width: 768px) {
          .card2-leftbox {
            flex-direction: column;
          }

          .card2-infobx-left .feimg {
            width: 100%;
            height: 200px;
          }

          .feature-box {
            margin-top: 20px;
          }

          .event-name {
            font-size: 24px;
          }
        }
      `}</style>
    </div>
  );
};

export default ActiveEventCard;
