import React, { useEffect, useState } from "react";
import { fetchAllEvents } from "../../action/events";
import ActiveEventCard from "../../components/eventCards/activeEventCard.js";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/pagination/paginationView";
import { changeHeaderState } from "../../reducer/slice/header";

const EventList = () => {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.changeSearchText.value ?? "");
  const [activeEvents, setActiveEvents] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const getAllEvents = async () => {
    const res = await fetchAllEvents(1, pageNo, pageSize, searchText);
    if (res?.status) {
      setActiveEvents(res.data.objects);
      setTotalRecords(res.data.total_items);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, [searchText, pageNo, pageSize]);

  useEffect(() => {
    dispatch(
      changeHeaderState({
        showDefault: true,
        headerTitle: "",
        navigationList: [],
      })
    );
  }, []);

  return (
    <div className="content-container">
      <div className="content-container-left">
        {activeEvents.length ? (
          <div className="section-row">
            <div className="heading-row">
              <div className="heading-left-group">
                <h2 className="item-heading1">All Events</h2>
              </div>
            </div>
            <div className="row g-3">
              {activeEvents.map((value, index) => (
                <ActiveEventCard key={index} value={value} colClass="col-md-12" />
              ))}
            </div>
          </div>
        ) : (
          <div className="no-events-message text-center mt-4">
            <h3>No events found</h3>
          </div>
        )}
        <div className="page-footer">
          <Pagination
            currentPage={pageNo}
            totalCount={totalRecords}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        </div>
      </div>
      <div className="content-container-right">
      </div>
    </div>
  );
};

export default EventList;
