import { API_URL } from "../utils/constants";
import axios from "axios";
import { requestWithToken } from "../helper/helper";

// Loading spinner component styles
const spinnerStyles = `
  .upload-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .upload-spinner-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }

  .upload-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 10px;
  }

  .upload-progress-text {
    margin-top: 10px;
    color: #333;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Add styles to document head
const styleSheet = document.createElement("style");
styleSheet.innerText = spinnerStyles;
document.head.appendChild(styleSheet);

// Loading spinner component
const showSpinner = (progress) => {
  const existingSpinner = document.querySelector('.upload-spinner-overlay');
  if (existingSpinner) {
    const progressText = existingSpinner.querySelector('.upload-progress-text');
    if (progressText) {
      progressText.textContent = `Uploading... ${progress}%`;
    }
    return;
  }

  const spinner = document.createElement('div');
  spinner.className = 'upload-spinner-overlay';
  spinner.innerHTML = `
    <div class="upload-spinner-container">
      <div class="upload-spinner"></div>
      <div class="upload-progress-text">Uploading guest file Please wait..</div>
    </div>
  `;
  document.body.appendChild(spinner);
};

const hideSpinner = () => {
  const spinner = document.querySelector('.upload-spinner-overlay');
  if (spinner) {
    spinner.remove();
  }
};

export const uploadFile = async (formData, onProgress) => {
  try {
    showSpinner(0);
    const token = localStorage.getItem("token");
    const response = await axios.post(`${API_URL}api/file-upload/`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        showSpinner(percentCompleted);
        if (onProgress) {
          onProgress(percentCompleted);
        }
      }
    });
    hideSpinner();
    return response.data;
  } catch (error) {
    hideSpinner();
    console.error("Error uploading file:", error);
    return {
      status: 0,
      message: error.response?.data?.message || "Error uploading file"
    };
  }
};

export const uploadLogo = async (formData) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/upload_company_logo`,
      formData
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteLogo = async () => {
  try {
    const response = await requestWithToken(
      "DELETE",
      "/api/delete_company_logo"
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
