import React, { useState, useEffect, useRef } from "react";
import sendMessageIcon from "../../assets/images/send.svg";
import "./RightSideBar.css";

const RightSideBar = ({
  conversationId,
  messages = [],
  onSendMessage,
  loading,
  error,
  event = {},
  guest = {},
}) => {
  const [messageText, setMessageText] = useState("");
  const messageContainerRef = useRef(null);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    const trimmedMessage = messageText.trim();
    if (!trimmedMessage || loading) return;

    try {
      await onSendMessage(trimmedMessage);
      setMessageText("");
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };
  console.log(messages);

  return (
    <div className="right-side-bar">
      <div className="header">
        <h2>{guest?.name || "Guest Name"}</h2>
      </div>

      <div className="messages-container" ref={messageContainerRef}>
        <div className="messages-content">
          {loading ? (
            <p className="loading">Loading messages...</p>
          ) : error ? (
            <p className="error">{error}</p>
          ) : !conversationId ? (
            <p className="no-conversation">Select a guest to start chatting.</p>
          ) : (
            [...messages].reverse().map((message, index) => (
              <div
                key={message.id || index}
                className={`message ${message.type}`}
              >
                <div className="message-bubble">
                  <div className="message-content">{message.message}</div>
                  <div className="message-timestamp">
                    {new Date(message.timestamp).toLocaleTimeString([], { 
                      hour: '2-digit', 
                      minute: '2-digit' 
                    })}
                  </div>
                  {message.type === 'outbound' && (
                    <span className="message-status">
                      ✓✓
                    </span>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {conversationId && (
        <form onSubmit={handleSendMessage} className="message-input-container">
          <textarea
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a message..."
            rows="1"
            disabled={loading}
          />
          <button
            type="submit"
            className="send-button"
            disabled={loading || !messageText.trim()}
          >
            <img src={sendMessageIcon} alt="Send" />
          </button>
        </form>
      )}
    </div>
  );
};

export default RightSideBar;
