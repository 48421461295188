import React, { useState, useEffect, useRef } from "react";

// images
import arrowUpIcon from "../../assets/images/arrow-up.svg";
import { chatWithAi } from "../../action/aiChat";
import botIcon from "../../assets/images/bot.svg";

const AiChatBox = () => {
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const chatBoxRef = useRef(null);

  const messageAi = async (message) => {
    const payload = {
      message,
    };

    // Update messages state to include the user's message
    setMessages((prevMessages) => [...prevMessages, { user: true, text: message }]);

    setQuestion("");
    setLoading(true);

    try {
      // Make the API call
      const res = await chatWithAi(payload);

      // Update messages state to include the AI's response
      setMessages((prevMessages) => [...prevMessages, { user: false, text: res.message }]);
    } catch (err) {
      // Handle the error
      setMessages((prevMessages) => [...prevMessages, { user: false, text: "An error occurred. Please try again." }]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && question.trim() !== "") {
      messageAi(question);
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, loading]);

  return (
    <div className="chat-container" style={{ 
      borderRadius: '16px',
      boxShadow: '0 4px 24px rgba(0, 0, 0, 0.06)',
      background: '#ffffff',
      width: '100%',
      height: '90vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div className="chat-header" style={{ 
        padding: '16px 24px',
        borderBottom: '1px solid #edf2f7',
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
      }}>
        <div className="ai-icon" style={{ 
          background: 'linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)',
          padding: '10px',
          borderRadius: '12px'
        }}>
          <img src={botIcon} alt="AI Assistant" style={{ width: '20px', height: '20px', filter: 'brightness(0) invert(1)' }} />
        </div>
        <span style={{ 
          fontSize: '18px',
          fontWeight: '600',
          color: '#1a202c'
        }}>AI Assistant</span>
      </div>

      <div className="chat-messages" ref={chatBoxRef} style={{ 
        flex: 1,
        overflowY: 'auto',
        padding: '24px',
        scrollBehavior: 'smooth'
      }}>
        {messages.map((message, index) => (
          <div key={index} style={{ 
            display: 'flex',
            justifyContent: message.user ? 'flex-end' : 'flex-start',
            marginBottom: '16px'
          }}>
            {!message.user && (
              <div style={{
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                background: 'linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '12px',
                flexShrink: 0
              }}>
                <img src={botIcon} alt="" style={{ width: '16px', height: '16px', filter: 'brightness(0) invert(1)' }} />
              </div>
            )}
            
            <div style={{
              maxWidth: '70%',
              padding: '12px 16px',
              borderRadius: '12px',
              background: message.user ? 'linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)' : '#f8fafc',
              color: message.user ? '#ffffff' : '#1a202c',
              fontSize: '14px',
              lineHeight: '1.5',
              boxShadow: message.user ? 
                '0 2px 8px rgba(79, 70, 229, 0.15)' : 
                '0 2px 8px rgba(0, 0, 0, 0.05)',
              border: message.user ? 'none' : '1px solid #e2e8f0'
            }}>
              {message.text}
            </div>

            {message.user && (
              <div style={{
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                background: '#e0e7ff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '12px'
              }}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" stroke="#4f46e5" strokeWidth="2"/>
                  <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" fill="#4f46e5"/>
                </svg>
              </div>
            )}
          </div>
        ))}

        {loading && (
          <div style={{ 
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px'
          }}>
            <div style={{
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              background: 'linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '12px'
            }}>
              <img src={botIcon} alt="" style={{ width: '16px', height: '16px', filter: 'brightness(0) invert(1)' }} />
            </div>
            <div style={{ 
              padding: '12px 16px',
              borderRadius: '12px',
              background: '#f8fafc',
              border: '1px solid #e2e8f0'
            }}>
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="chat-input-container" style={{ 
        padding: '16px 24px',
        borderTop: '1px solid #edf2f7',
        background: '#ffffff'
      }}>
        <div style={{ position: 'relative' }}>
          <input
            type="text"
            placeholder="Type a message..."
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            onKeyPress={handleKeyPress}
            style={{ 
              width: '100%',
              padding: '12px 16px',
              paddingRight: '48px',
              fontSize: '14px',
              border: '1px solid #e2e8f0',
              borderRadius: '8px',
              outline: 'none',
              transition: 'all 0.2s',
              background: '#ffffff'
            }}
          />
          <button
            onClick={() => {
              if (question.trim() !== "") {
                messageAi(question);
              }
            }}
            style={{ 
              position: 'absolute',
              right: '8px',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '32px',
              height: '32px',
              borderRadius: '8px',
              background: 'linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)',
              border: 'none',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.2s'
            }}
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>

      <style>{`
        .typing-indicator {
          display: flex;
          gap: 4px;
        }
        
        .typing-indicator span {
          width: 6px;
          height: 6px;
          background: #4f46e5;
          border-radius: 50%;
          animation: bounce 1.4s infinite ease-in-out;
        }
        
        .typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
        .typing-indicator span:nth-child(2) { animation-delay: -0.16s; }
        
        @keyframes bounce {
          0%, 80%, 100% { transform: scale(0); }
          40% { transform: scale(1); }
        }

        .chat-messages::-webkit-scrollbar {
          width: 6px;
        }
        
        .chat-messages::-webkit-scrollbar-track {
          background: transparent;
        }
        
        .chat-messages::-webkit-scrollbar-thumb {
          background: #e2e8f0;
          border-radius: 3px;
        }
        
        .chat-messages::-webkit-scrollbar-thumb:hover {
          background: #cbd5e1;
        }

        input:focus {
          border-color: #4f46e5;
          box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
        }

        button:hover {
          transform: translateY(-50%) scale(1.05);
          box-shadow: 0 2px 8px rgba(79, 70, 229, 0.2);
        }
      `}</style>
    </div>
  );
};

export default AiChatBox;
