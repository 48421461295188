import React from "react";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import "./ChatContainer.css";

const ChatContainer = ({
  conversations,
  activeConversation,
  onSelectConversation,
  messages,
  onSendMessage,
  loading,
  error,
  guest,
}) => {
  return (
    <div className="chat-container">
      <LeftSideBar
        conversations={conversations}
        activeConversation={activeConversation}
        onSelectConversation={onSelectConversation}
      />
      <RightSideBar
        conversationId={activeConversation}
        messages={messages}
        onSendMessage={onSendMessage}
        loading={loading}
        error={error}
        guest={guest}
      />
    </div>
  );
};

export default ChatContainer; 