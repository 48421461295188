import { Suspense, Fragment, useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import {
  fetchSubscriptionDetails,
  fetchSubscriptionPlan,
  getDashboardDetails,
} from "../action/events";
import {
  getUserInfo,
  updateUserInfo
} from "../action/user";
import {
  getCountryDetails
} from "../action/country";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import deleteIcon from "../assets/images/trash-brown.svg";
import { deleteLogo, uploadLogo } from "../action/upload";
import showNotification from "../components/notification/notification";

export function ProfilePage() {
  const userDetails = useSelector((state) => state.userDetails.value);
  const [loading, setLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [userInformation, setUserInformation] = useState([]);
  const [showSubscription, setShowSubscription] = useState(false);
  const [subDetails, setSubDetails] = useState([]);
  const [subscriptPlans, setSubscriptionPlans] = useState([]);
  const [image, setImage] = useState(null);
  const [countries, setCountries] = useState(null);
  const [baseURL, setBaseUrl] = useState(process.env.REACT_APP_API_ENDPOINT);
 
  const renderDefaultIcon = () => {
    return (
      <div 
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '2.5rem',
          fontWeight: 'bold',
          color: '#CFB53B',
          textTransform: 'uppercase',
          backgroundColor: '#f8f9fa',
          borderRadius: '10px'
        }}
      >
        {companyDetails?.company?.name ? companyDetails.company.name.slice(0, 2) : 'CO'}
      </div>
    );
  };

  useEffect(() => {
    const getCompany = async () => {
      const res = await getDashboardDetails();
      if (res?.status) {
        setCompanyDetails(res.data);
        getSubscriptionDetails(res.data.company.id);
        if (res.data.company.logo) {
          setImage(baseURL + res.data.company.logo);
        }
      }
    };
    getCompany();
  }, []);

  const handleImageChange = async (e) => {
    const file = e?.target?.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append("logo", file);

    if (companyDetails?.company?.id) {
      formData.append("company_id", companyDetails.company.id);
    } else {
      console.error("Company ID is missing!");
      return;
    }

    try {
      const response = await uploadLogo(formData);
      if (response?.status) {
        const imageUrl = `${baseURL}${response.data.logo}`;
        setImage(imageUrl);
        console.log("Image uploaded:", imageUrl);
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageDelete = async () => {
    try {
      const response = await deleteLogo();
      if (response?.status) {
        setImage(null);
        console.log("Image deleted successfully");
      } else {
        console.log("Failed to delete image");
      }
    } catch (error) {
      console.log("Error deleting image:", error);
    }
  };

  const addGuestFormik = useFormik({
    initialValues: {
      plan_id: "",
      company_id: "",
    },
    onSubmit: async (values) => {
      console.log("JDOF", values);
    },
  });

  const editUserFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_id: "",
      user_id: userInformation?.id || "",
      first_name: userInformation?.first_name || "",
      last_name: userInformation?.last_name || "",
      email: userInformation?.email || "",
      phone_number: userInformation?.phone_number || "",
    },
    onSubmit: async (values) => {
      try {
        const response = await updateUserInfo(values,values.user_id);
        if (response?.status) {
          showNotification("Profile Updated successfully", "success");
        } else {
          showNotification("Profile Updated feild", "danger");
        }
      } catch (error) {
        showNotification("Profile Updated feild "+error, "danger");
      }
    },
  });
  
  const getUser = async () => {
    const res = await getUserInfo();
    if (res?.status) {
      setUserInformation(res.data);
    }
  };
  const getCountry = async () => {
    const res = await getCountryDetails();
    if (res?.status) {
      setCountries(res.data);
    }
  };

  const getSubscriptionDetails = async (id) => {
    setLoading(true);
    const res = await fetchSubscriptionDetails(id);
    setLoading(false);
    if (res?.status) {
      setSubDetails(res.data);
    }
  };

  const getSubscriptionPlan = async () => {
    const res = await fetchSubscriptionPlan();
    setSubscriptionPlans(res);
  };

  useEffect(() => {
    if (userDetails?.is_companyAdmin == true) {
      getUser();
      getCountry();
      getSubscriptionPlan();
    }
  }, [userDetails]);

  return (
    <div>
      {!loading ? (
        <div className="profile_page">
          <div className="row">
            <div className="col-lg-8">
              <div className="profile_container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="profile_card">
                      <div className="profile-image-box">
                        {image ? (
                          <img src={image} alt="Company Logo" />
                        ) : (
                          renderDefaultIcon()
                        )}
                      </div>
                      <div className="profile-action">
                        <button
                          className="btn-theme outline"
                          onClick={() => document.getElementById("fileInput").click()}
                        >
                          Change
                        </button>
                        <input
                          id="fileInput"
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <button
                          className="iconbtn no-bg"
                          onClick={handleImageDelete}
                        >
                          <img src={deleteIcon} alt="Delete" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-3">
                        <div class="input-box">
                          <div class="input-label">First Name</div>
                          <div class="input_field">
                            <input
                              name="first_name"
                              type="text"
                              class="input"
                              placeholder=" Enter Name"
                              value={userInformation.first_name}
                              onChange={(e) => setUserInformation({ ...userInformation, first_name: e.target.value })}

                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div class="input-box">
                          <div class="input-label">last Name</div>
                          <div class="input_field">
                            <input
                              name="last_name"
                              type="text"
                              class="input"
                              placeholder=" Enter Name"
                              value={userInformation.last_name}
                              onChange={(e) => setUserInformation({ ...userInformation, last_name: e.target.value })}

                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="input-box">
                          <div class="input-label">Role</div>
                          <div class="input_field">
                            <input
                              name="permission"
                              readOnly
                              type="text"
                              class="input"
                              placeholder="Enter Role"
                              value={userInformation.is_companyAdmin ? "Company" : "User"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div class="input-box">
                          <div class="input-label">Email</div>
                          <div class="input_field">
                            <input
                              name="email"
                              type="email"
                              class="input"
                              placeholder="Enter Your Email ID"
                              value={userInformation.email}
                              onChange={(e) => setUserInformation({ ...userInformation, email: e.target.value })}

                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="btn-box frm-sbmit-grp justify-center">
                      <button class="btn-theme bg-dark" onClick={editUserFormik.handleSubmit}>Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader-box">
          <div className="loader"></div>
        </div>
      )}

      <Offcanvas
        show={showSubscription}
        onHide={() => {
          setShowSubscription(false);
        }}
        placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Subscription Plan</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div class="input-box m-3">
            <form onSubmit={addGuestFormik.handleSubmit}>
              <div class="input-label">Select Plan</div>
              <div class="input_field">
                <select
                  name="plan_id"
                  value={addGuestFormik.values.plan_id}
                  onChange={addGuestFormik.handleChange}
                  class="input">
                  <option value="">Select Status</option>
                  {subscriptPlans.map((value) => {
                    return <option value={value.id}>{value.name}</option>;
                  })}
                </select>
              </div>
              <div class="d-flex about-button-center mt-3">
                <button
                  type="submit"
                  class="submit-btn active">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default ProfilePage;
