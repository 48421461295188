import React from 'react';
import AiChatBox from '../../components/aiChatBox/aiChatBox';

const Chating = () => {

    return (
        <div  className='container' >
            <AiChatBox />
        </div>
    );
};

export default Chating;
