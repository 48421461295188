/* global FB */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../components/customSearchDropdown.js";
import { useDispatch, useSelector } from "react-redux";
import { changeHeaderState } from "../../reducer/slice/header";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import showNotification from "../../components/notification/notification";
import { fetchEventDetails, fetchActiveEvents } from "../../action/events";
import { setSelectedEventType } from "../../reducer/slice/eventSlice";
import { fetchAllGuests } from "../../reducer/slice/guestListSlice";
import { Link } from "react-router-dom";
import * as conversation from "../../action/conversation.js";
import { sendWhatsappMessage } from "../../action/whatsappMessage.js";
import { SendEmails } from "../../action/communication";
import { fetchTemplates, sendTemplateMessage } from '../../action/templates.js';

export function CommunicationPage() {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [optionsOfTemplates, setOptionsOfTemplates] = useState([]);
  const [templateParameters, setTemplateParameters] = useState({});

  const selectedEventType = useSelector((state) => state.events.selectedEventType);
  const guestAllList = useSelector((state) => state.guestList.allGuests);

  useEffect(() => {
    // Fetch event details and guest list when component mounts
    const fetchEventData = async () => {
      const res = await fetchEventDetails(eventId);
      if (res.status) {
        dispatch(setSelectedEventType(res.data));
        dispatch(fetchAllGuests({ eventId }));
      }
    };
    fetchEventData();
  }, [dispatch, eventId]);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const response = await fetchTemplates();
        if (response.data) {
          const formattedTemplates = response.data.map(template => ({
            text: template.name,
            id: template.id,
            content: template.content,
            parameters: template.parameters
          }));
          setOptionsOfTemplates(formattedTemplates);
          
          if (formattedTemplates.length > 0) {
            setSelectedTemplate(formattedTemplates[0]);
            initializeParameters(formattedTemplates[0].parameters);
          }
        }
      } catch (error) {
        console.error('Error loading templates:', error);
      }
    };

    loadTemplates();
  }, []);

  const initializeParameters = (parameters) => {
    const paramObj = {};
    parameters.forEach(param => {
      paramObj[param] = '';
    });
    setTemplateParameters(paramObj);
  };

  const addEventFormik = useFormik({
    initialValues: {
      event: eventId,
      guests: [],
      sendEmail: false,
      sendWhatsapp: false,
      whatsappTemplate: "",
      emailMessage: "",
      whatsappTemplateMessage: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.event) {
        errors.event = "Please select event";
      }
      return errors;
    },
  });

  const handleOptionChange = (selectedGuests) => {
    // selectedGuests already contains id and name
    setSelectedOptions(selectedGuests);
  };

  const handleTemplateChange = (option) => {
    setSelectedTemplate(option);
    setIsOpen(false);
    initializeParameters(option.parameters);
    addEventFormik.setFieldValue("whatsappTemplate", option.text);
    addEventFormik.setFieldValue("whatsappTemplateMessage", option.content);
  };

  const handleParameterChange = (param, value) => {
    setTemplateParameters(prev => ({
      ...prev,
      [param]: value
    }));
  };

  const formatMessageWithParameters = (template, parameters) => {
    let messageText = template.content;
    template.parameters.forEach(param => {
      messageText = messageText.replace(`[${param}]`, parameters[param] || '');
    });
    return messageText;
  };
  const handleSendWhatsapp = async () => {
    try {
      if (!selectedTemplate || selectedOptions.length === 0) {
        Swal.fire({
          title: "Error",
          text: "Please select a template and recipients",
          icon: "error"
        });
        return;
      }

      // Check if all parameters are filled
      const missingParams = selectedTemplate.parameters.filter(param => !templateParameters[param]);
      if (missingParams.length > 0) {
        Swal.fire({
          title: "Error",
          text: `Please fill in all template parameters: ${missingParams.join(', ')}`,
          icon: "error"
        });
        return;
      }

      Swal.fire({
        title: "Please wait...",
        text: "Sending WhatsApp messages...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const results = [];
      
      // Get parameter values in the correct order
      const parameterValues = selectedTemplate.parameters.map(param => templateParameters[param] || '');
      
      for (const guest of selectedOptions) {
        try {
          // Get or create conversation
          const conversationResult = await conversation.getEventConversationByEventAndGuest(
            eventId,
            guest.id
          );

          if (!conversationResult || !conversationResult.conversation) {
            throw new Error(`Failed to get conversation for guest ${guest.name}`);
          }

          // Send template message
          const messageResponse = await sendTemplateMessage({
            conversation_id: conversationResult.conversation.id,
            template_name: selectedTemplate.text,
            parameters: parameterValues,
            event_id: eventId
          });

          results.push({
            guest: guest.name,
            status: 'success',
            message: 'Message sent successfully'
          });

        } catch (err) {
          results.push({
            guest: guest.name,
            status: 'error',
            message: err.message || 'Failed to send message'
          });
        }
      }

      // Show results summary
      Swal.close();
      const successCount = results.filter(r => r.status === 'success').length;
      
      if (successCount > 0) {
        Swal.fire({
          title: "Messages Sent",
          html: `Successfully sent: ${successCount}<br>Failed: ${results.length - successCount}`,
          icon: successCount === results.length ? "success" : "warning",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to send all messages",
          icon: "error"
        });
      }

    } catch (error) {
      console.error("Error sending messages:", error);
      Swal.fire({
        title: "Error",
        text: error.message || "Failed to send messages",
        icon: "error"
      });
    }
  };
  const handleSendEmail = async () => {
    try {
      if (!selectedTemplate || selectedOptions.length === 0) {
        Swal.fire({
          title: "Error",
          text: "Please select a template and recipients",
          icon: "error"
        });
        return;
      }

      // Check if all parameters are filled
      const missingParams = selectedTemplate.parameters.filter(param => !templateParameters[param]);
      if (missingParams.length > 0) {
        Swal.fire({
          title: "Error",
          text: `Please fill in all template parameters: ${missingParams.join(', ')}`,
          icon: "error"
        });
        return;
      }

      // Format message with parameters once
      let messageText = selectedTemplate.content;
      for (const param of selectedTemplate.parameters) {
        const value = templateParameters[param] || '';
        messageText = messageText.replace(`[${param}]`, value);
      }

      let response;
      for (const guest of selectedOptions) {
        try {
          Swal.fire({
            title: "Please wait...",
            text: `Sending Email to ${guest.name}...`,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          const res = await SendEmails({
            event: eventId,
            guest: guest.id,
            message_text: messageText,
          });

          response = res;
        } catch (err) {
          Swal.fire({
            title: "Error",
            text: `Failed to send email to ${guest.name}: ${err.message}`,
            icon: "error"
          });
          return;
        }
      }

      if (response?.status === "success") {
        Swal.close();
        Swal.fire({
          title: "Success",
          text: "Emails sent successfully!",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "There was an error when sending the template: " + response?.message,
          icon: "error",
        });
      }
    } catch (error) {
      Swal.close();
      console.error("Error sending email:", error);
      Swal.fire({
        title: "Error",
        text: "Message sending failed!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "sendEmail" && checked) {
      addEventFormik.setFieldValue("sendEmail", true);
      addEventFormik.setFieldValue("sendWhatsapp", false);
    } else if (name === "sendWhatsapp" && checked) {
      addEventFormik.setFieldValue("sendWhatsapp", true);
      addEventFormik.setFieldValue("sendEmail", false);
    } else {
      addEventFormik.handleChange(e);
    }
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-content-box">
        <form onSubmit={addEventFormik.handleSubmit}>
          <div className="row">
            <div className="col-lg-7">
              <div className="card-custom card3">
                <div className="inline-checkbox">
                  <div className="custom_checkbox">
                    <input
                      name="sendEmail"
                      id="sendEmail"
                      type="checkbox"
                      className="m-2"
                      checked={addEventFormik.values.sendEmail}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="sendEmail">Email</label>
                  </div>

                  <div className="custom_checkbox">
                    <input
                      id="sendWhatsapp"
                      name="sendWhatsapp"
                      type="checkbox"
                      className="m-2"
                      checked={addEventFormik.values.sendWhatsapp}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="sendWhatsapp">Whatsapp</label>
                  </div>
                </div>

                <div className="row">
                  <div className="selected-event-name">
                    {selectedEventType.name}
                  </div>

                  <div className="col-lg-6">
                    <div className="input-box">
                      <div className="input-label">Select Guests</div>
                      <div className="custom-dropdown-box">
                        <CustomDropdown
                          options={guestAllList}
                          placeholder="Select guests"
                          onChange={handleOptionChange}
                          selectedOptions={selectedOptions}
                          message={"guest selected"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    {(addEventFormik.values.sendWhatsapp || addEventFormik.values.sendEmail) && (
                      <div>
                        <div className="input-box">
                          <div className="input-label">Select Template</div>
                          <div className="input_field">
                            <div className="custom-dropdown">
                              <div
                                className="custom-dropdown__control"
                                onClick={() => setIsOpen(!isOpen)}
                              >
                                <div className="custom-dropdown__selected">
                                  {selectedTemplate ? selectedTemplate.text : "Select Template"}
                                </div>
                                <div className="custom-dropdown__arrow">
                                  {isOpen ? "▲" : "▼"}
                                </div>
                              </div>
                              {isOpen && (
                                <div className="custom-dropdown__menu">
                                  <div className="custom-dropdown__options">
                                    {optionsOfTemplates.map((template) => (
                                      <div
                                        key={template.id}
                                        className="custom-dropdown__option"
                                        onClick={() => handleTemplateChange(template)}
                                      >
                                        {template.text}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {(addEventFormik.values.sendWhatsapp || addEventFormik.values.sendEmail) && selectedTemplate && (
              <div className="col-lg-5">
                <div className="card-custom card3">
                  <div className="input-box">
                    <div className="input-label">Message Template</div>
                    <div className="input_field">
                      <textarea
                        rows={8}
                        value={selectedTemplate.content}
                        readOnly
                        className="input"
                        placeholder="Message Template"
                      />
                    </div>

                    {selectedTemplate.parameters && selectedTemplate.parameters.length > 0 && (
                      <div className="template-parameters mt-3">
                        <div className="input-label">Template Parameters</div>
                        {selectedTemplate.parameters.map((param, index) => (
                          <div key={index} className="input_field mt-2">
                            <input
                              type="text"
                              className="input"
                              placeholder={param}
                              value={templateParameters[param] || ''}
                              onChange={(e) => handleParameterChange(param, e.target.value)}
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    <button
                      type="button"
                      className="btn-theme btn bg-dark mt-3"
                      onClick={addEventFormik.values.sendWhatsapp ? handleSendWhatsapp : handleSendEmail}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CommunicationPage;
