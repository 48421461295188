import React, { useState } from "react";
import "./LeftSideBar.css";
import { FaSearch, FaEllipsisV } from 'react-icons/fa';

const LeftSideBar = ({ conversations = [], activeConversation, onSelectConversation }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredConversations = conversations.filter(conv => 
    conv.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log(filteredConversations);
  return (
    <div className="left-side-bar">
      <div className="left-header">
        <div className="user-profile">
          <div className="user-avatar">
            <span>M</span>
          </div>
          <div className="header-actions">
            <button className="icon-button">
              <FaEllipsisV />
            </button>
          </div>
        </div>
      </div>
      
      <div className="search-container">
        <div className="search-wrapper">
          <FaSearch className="search-icon" />
          <input 
            type="text" 
            placeholder="Search or start new chat"
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="conversations-list">
        {filteredConversations.length === 0 ? (
          <div className="no-conversations">
            {searchQuery ? "No matching conversations" : "No conversations yet"}
          </div>
        ) : (
          filteredConversations.map((conversation) => (
            <div
              key={conversation.id}
              className={`conversation-item ${
                activeConversation === conversation.id ? 'active' : ''
              }`}
              onClick={() => onSelectConversation(conversation.id)}
            >
              <div className="conversation-avatar">
                {conversation.name?.charAt(0).toUpperCase() || 'G'}
              </div>
              <div className="conversation-info">
                <div className="conversation-header">
                  <div className="conversation-name">
                    {conversation.name || "Guest"}
                  </div>
                  <div className="conversation-time">
                    {conversation.lastMessageTime || ""}
                  </div>
                </div>
                <div className="conversation-footer">
                  <div className="conversation-last-message">
                  </div>
                  {conversation.unreadCount > 0 && (
                    <div className="unread-count">{conversation.unreadCount}</div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default LeftSideBar; 