// CustomDropdown.js
import React, { useState, useEffect, useRef } from "react";
import "../styles/CustomDropdown.css"; // Include your CSS styles
import selectIcon from "../assets/images/selectArrow.svg";
import selectControl from "../assets/images/selectControll.svg";
import reArrange from "../assets/images/re-arrange.svg";
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { Dropdown, Form } from 'react-bootstrap';

const CustomSelectWithIconGuest = ({
  extraclass,
  columns,
  setColumns
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleColumn = (kn) => {
    for (let x of columns) {
      if (x.keyName === kn) {
        x.show = !x.show
      }
    }
    setColumns([...columns])
  }

  const handleSelectAll = (checked) => {
    setColumns(columns.map(col => ({
      ...col,
      show: checked
    })));
  };

  const lockedCol = columns.filter(el => el.locked)
  const unlockedCol = columns.filter(el => !el.locked)

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...unlockedCol];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setColumns([...lockedCol, ...data])
    },
    nodeSelector: 'li',
    handleSelector: 'span'
  };

  return (
    <div
      className={`custom-select withIcon ${extraclass}`}
      ref={dropdownRef}>
      <div
        className="custom-select__control"
        onClick={() => setIsOpen(!isOpen)}>
        <div className="custom-select__arrow">
          <img src={selectControl} alt="" />
        </div>
        <div className="custom-select__selected">Manage Columns</div>
        <div className="custom-select__arrow last">
          {isOpen ? (
            <img src={selectIcon} alt="" />
          ) : (
            <img
              src={selectIcon}
              className="selectDownImg"
              alt=""
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="custom-select__menu">
          <div className="custom-select__option d-flex align-items-center">
            <div className="custom_checkbox">
              <input
                id="select-all"
                name="select-all"
                type="checkbox"
                checked={columns.every(col => col.show)}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
              <label htmlFor="select-all">Select All</label>
            </div>
          </div>
          <hr className="dropdown-divider" />
          <ReactDragListView {...dragProps}>
            <ol className="custom-select__options">
              {unlockedCol.map((el, i) => {
                return (<li className="custom-select__option d-flex align-items-center" key={`cOptions${i + 1}`}>
                  <span className="reArrange_icon-box">
                    <img src={reArrange} alt="" />
                  </span>
                  <div className="custom_checkbox">
                    <input
                      id={el.keyName}
                      name={el.keyName}
                      type="checkbox"
                      checked={el.show}
                      onChange={() => handleColumn(el.keyName)}
                    />
                    <label htmlFor={el.keyName}>{el.name}</label>
                  </div>
                </li>)
              })}
            </ol>
          </ReactDragListView>
        </div>
      )}
    </div>
  );
};

export default CustomSelectWithIconGuest;
