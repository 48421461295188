import { requestWithToken } from "../helper/helper";

export const getCoinRequirement = async (eventType) => {
  try {
    const response = await requestWithToken("GET",`/api/coin-settings/get_coin_requirement/?event_type=${eventType}`);
    return response;
  } catch (error) {
    console.error("Error getting coin requirement:", error);
    return {
      status: 0,
      message: error.message || "Failed to get coin requirement"
    };
  }
}; 