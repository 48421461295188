import React from "react";
import Slider from "react-slick";
import moment from "moment";
import "../../styles/style.css";
import defaultEventIcon from "../../assets/images/eventimg1.jpg";
import starIcon from "../../assets/images/star.svg";
import guestIcon from "../../assets/images/guest.svg";
import logisticIcon from "../../assets/images/logis.svg";
import acomoIcon from "../../assets/images/acomo.svg";
import communicationIcon from "../../assets/images/communication.svg";
import calendarIcon from "../../assets/images/calendor.svg";
import locationIcon from "../../assets/images/location.svg";
import { Link } from "react-router-dom";

const SliderCard = ({ activeEvents, colClass = "col-md-12" }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    className: "slider",
    arrows: true,
    autoplay: false,
    autoplaySpeed: 5000,
    cssEase: "ease-in-out",
    fade: false,
    pauseOnHover: true,
    swipeToSlide: true,
    draggable: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
          ...style, 
          display: "block",
          background: "#CFB53B",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          right: "-20px",
          zIndex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
          ...style, 
          display: "block",
          background: "#CFB53B",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          left: "-20px",
          zIndex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        onClick={onClick}
      />
    );
  }

  const getEventImage = (eventType) => {
    switch(eventType) {
      case 'Wedding':
        return 'https://images.unsplash.com/photo-1519741497674-611481863552?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80';
      case 'PreWedding':
        return 'https://images.unsplash.com/photo-1583939003579-730e3918a45a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80';
      case 'Celebration':
        return 'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80';
      default:
        return defaultEventIcon;
    }
  };

  return (
    <div className={colClass}>
      <div className="slider-container">
        <Slider {...settings}>
          {activeEvents.map((value) => (
            <div key={value.id} className="card-custom card2">
              <Link to={`/event-details/${value.id}`} className="card-link">
                <div className="row g-0">
                  <div className="col-md-8">
                    <div className="card2-leftbox">
                      <div className="card2-infobx-left">
                        <img 
                          src={getEventImage(value.event_type)} 
                          className="feimg" 
                          alt={`${value.event_type} Event`} 
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultEventIcon;
                          }}
                        />
                      </div>
                      <div className="card2-infobx-right">
                        <div className="headingbx">
                          <div className="event-type">{value.event_type}</div>
                          <h3 className="event-name">{value.name}</h3>
                        </div>
                        <ul className="event-details-list">
                          <li>
                            <span className="icon-wrapper">
                              <img src={calendarIcon} alt="Calendar" />
                            </span>
                            <p>{moment(value.date).format("DD MMM YYYY")}</p>
                          </li>
                          <li>
                            <span className="icon-wrapper">
                              <img src={locationIcon} alt="Location" />
                            </span>
                            <p>{value.location}</p>
                          </li>
                          <li>
                            <span className="icon-wrapper">
                              <img src={starIcon} alt="Venue" />
                            </span>
                            <p>{value.venue}</p>
                          </li>
                          {value?.event_object?.sub_events?.length > 0 && (
                            <li>
                              <span className="icon-wrapper">
                                <img src={starIcon} alt="Events" />
                              </span>
                              <p className="sub-events">
                                {value?.event_object?.sub_events
                                  ?.slice(0, 2)
                                  ?.map((subEvent, index) => (
                                    <span key={index} className="sub-event-name">
                                      {subEvent.name}{index !== 1 && value?.event_object?.sub_events.length > 1 ? ", " : ""}
                                    </span>
                                  ))}
                                {value?.event_object?.sub_events?.length > 2 && (
                                  <span className="more-events">
                                    +{value?.event_object?.sub_events?.length - 2} More
                                  </span>
                                )}
                              </p>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <div className="feature-box">
                      <ul className="feature-list">
                        <li>
                          <Link
                            to={`/guests/${value?.id}/${value?.company}`}
                            className="feature-link"
                          >
                            <span className="icon-wrapper">
                              <img src={guestIcon} alt="Guest Management" />
                            </span>
                            <span>Guest Management</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/logistics/${value?.id}`} className="feature-link">
                            <span className="icon-wrapper">
                              <img src={logisticIcon} alt="Logistics" />
                            </span>
                            <span>Logistics</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/hotels/${value?.id}`} className="feature-link">
                            <span className="icon-wrapper">
                              <img src={acomoIcon} alt="Accommodation" />
                            </span>
                            <span>Accommodation</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/communication/${value?.id}`} className="feature-link">
                            <span className="icon-wrapper">
                              <img src={communicationIcon} alt="Communication" />
                            </span>
                            <span>Communication</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/conversation/${value?.id}`} className="feature-link">
                            <span className="icon-wrapper">
                              <img src={communicationIcon} alt="Conversation" />
                            </span>
                            <span>Conversation</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>

    </div>
  );
};

export default SliderCard;
