import React from 'react';
import { RouterProvider } from "react-router-dom";
import route from "./routes/route";
import "./styles/style.css";
import "./styles/login.css";

function App() {
  return <RouterProvider router={route} fallbackElement={<div>Loading...</div>} />;
}

export default App;
