import React, { useState, useEffect } from "react";
import ChatContainer from "../../components/whatsapp/ChatContainer";
import {
  fetchGuests,
  getEventConversation,
  fetchMessages,
  sendMessage,
  createEventConversation,
  showGuest
} from "../../action/conversation.js";
import { useParams } from "react-router-dom";
import "./WhatsAppChat.css";

const WhatsAppChat = () => {
  const { eventId } = useParams();
  const [guestList, setGuestList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [conversationId, setConversationId] = useState(null);
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [guest, setGuest] = useState(null);

  // Format guests as conversations
  const conversations = guestList.map(guest => ({
    id: guest.id,
    name: `${guest.name}`,
    lastMessage: messages[guest.id]?.slice(-1)[0]?.message || "No messages yet",
    lastMessageTime: messages[guest.id]?.slice(-1)[0]?.timestamp ? 
      new Date(messages[guest.id]?.slice(-1)[0]?.timestamp).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      }) : "",
    unreadCount: 0
  }));

  // Add this function to fetch latest messages
  const fetchLatestMessages = async (convId) => {
    try {
      const convMessages = await fetchMessages(convId);
      if (Array.isArray(convMessages)) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          [convId]: convMessages.sort((a, b) => 
            new Date(b.timestamp) - new Date(a.timestamp)
          ),
        }));
      }
    } catch (err) {
      console.error('Error fetching latest messages:', err);
    }
  };

  // Update useEffect to fetch messages periodically
  useEffect(() => {
    const fetchEventData = async () => {
      setLoading(true);
      try {
        const event = await getEventConversation(eventId);
        setSelectedEvent(event);
      } catch (err) {
        setError("Failed to fetch event details.");
      } finally {
        setLoading(false);
      }
    };

    const fetchGuestsData = async () => {
      setLoading(true);
      try {
        const guests = await fetchGuests(eventId);
        setGuestList(guests.data.objects.guestDetails);
      } catch (err) {
        setError("Failed to fetch guests.");
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
    fetchGuestsData();

    // Fetch messages periodically if there's an active conversation
    let intervalId;
    if (conversationId) {
      // Initial fetch
      fetchLatestMessages(conversationId);
      
      // Set up periodic fetch
      intervalId = setInterval(() => {
        fetchLatestMessages(conversationId);
      }, 5000); // Fetch every 5 seconds
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [eventId, conversationId]);

  const handleSendMessage = async (messageText) => {
    if (!conversationId || !messageText.trim()) return;

    try {
      // Optimistically add the message
      const newMessage = {
        id: Date.now(),
        message: messageText,
        sender: "me",
        guest: null,
        timestamp: new Date().toISOString(),
      };

      // Add new message to the beginning of the array
      setMessages((prevMessages) => ({
        ...prevMessages,
        [conversationId]: [
          newMessage,
          ...(prevMessages[conversationId] || [])
        ],
      }));

      // Send the message
      const response = await sendMessage({
        conversation_id: conversationId,
        message_text: messageText,
        guest: guest.id.toString(),
        event: eventId
      });

      if (!response || response.status === 'error') {
        // Remove the optimistic message if sending failed
        setMessages((prevMessages) => ({
          ...prevMessages,
          [conversationId]: prevMessages[conversationId].filter(msg => msg.id !== newMessage.id)
        }));
        throw new Error(response?.message || 'Failed to send message');
      }

    } catch (err) {
      console.error("Error sending message:", err);
      setError(err.message || "Failed to send message. Please try again.");
    }
  };

  const handleSelectGuest = async (guestId) => {
    setLoading(true);
    setError(null);
    
    try {
      const result = await createEventConversation({ 
        event: eventId, 
        guest: guestId 
      });

      if (!result || !result.conversation) {
        throw new Error('Failed to create conversation');
      }

      const { conversation } = result;
      setConversationId(conversation.id);

      const guestDetails = await showGuest(guestId);
      if (!guestDetails) {
        throw new Error('Failed to fetch guest details');
      }
      setGuest(guestDetails);
      console.log("guest : " + guest);
      // Always fetch latest messages when selecting a guest
      await fetchLatestMessages(conversation.id);

    } catch (err) {
      console.error('Error in handleSelectGuest:', err);
      setError(err.message || "Failed to load conversation.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="whatsapp-chat-page">
      <ChatContainer
        conversations={conversations}
        activeConversation={conversationId}
        onSelectConversation={handleSelectGuest}
        messages={messages[conversationId] || []}
        onSendMessage={handleSendMessage}
        loading={loading}
        error={error}
        guest={guest}
      />
    </div>
  );
};

export default WhatsAppChat;
