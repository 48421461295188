import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { addGuest } from "../../action/guests";
import showNotification from "../../components/notification/notification";
import { Link, useNavigate, useParams } from "react-router-dom";
import { uploadFile } from "../../action/upload";
import { fetchAllSubEvents, fetchSubEvents } from "../../action/subEvents";
import CustomDropdown from "../../components/customSearchDropdown";
import viewEdit from "../../assets/images/viewEdit.svg";
import { guestValidationSchema } from "./validation.js";

const GuestAdd = ({ closeCanvas }) => {
  const { eventId, cpId } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState("Form");
  const [eventType, setEventType] = useState("");
  const [guestListFile, setGuestListFile] = useState();
  const [subEvents, setSubEvents] = useState([]);
  const addGuestFormik = useFormik({
    initialValues: {
      company: cpId,
      event: eventId,
      subevent: [],
      name: "",
      email: "",
      contact_number: "",
      contact_number_prefix: "+91",
      whatsapp_verified: false,
      rsvp: "No",
      notes: "",
      hampergift: "",
      salutation: "Mr. ",
      Address: "",
      group: "",
      subgroup: "",
      alternate_contact_number: "",
      alternate_contact_number_prefix: "+91",
      save_the_date_email: false,
      save_the_date_whatsapp: false,
      whatsapp_verified: false,
      wedding_invite: "Pending",
      reception_invite: "Pending",
      to_be_invited_for: "",
    },
    validationSchema: guestValidationSchema,
    onSubmit: async (values) => {
      values.name = values.salutation + " " + values.name;
      const res = await addGuest([values], eventId);
      if (res.status) {
        closeCanvas();
        showNotification("Guest added successfully", "success");
      } else {
        showNotification(res.message, "danger");
      }
    },
  });

  const uploadGuestListFile = async (file) => {
    try {
      if (!file) return;
      
      const formData = new FormData();
      formData.append("file", file);
      formData.append("event_id", eventId);
      
      const res = await uploadFile(formData);
      if (res.status) {
        showNotification("Guest list uploaded successfully", "success");
        closeCanvas();
      } else {
        showNotification(res.message || "Error uploading guest list", "danger");
      }
    } catch (error) {
      console.error("Error uploading guest list:", error);
      showNotification("Error uploading guest list", "danger");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setGuestListFile(file);
      uploadGuestListFile(file);
    }
  };

  const getSubEvents = async () => {
    const res = await fetchAllSubEvents(eventId);
    console.log(res, "jkjk");
    if (res.status) {
      setEventType(res.data.data?.eventDetails?.event_type || "");
      setSubEvents(
        res.data.data.subEventDetails.map((value) => {
          return { value: value.id, label: value.name };
        })
      );
    }
  };

  useEffect(() => {
    getSubEvents();
  }, [eventId]);

  const handleOptionChange = (selectedOptions) => {
    let isExist = addGuestFormik.values.subevent.some(
      (value) => value == selectedOptions.value
    );
    if (isExist) {
      addGuestFormik.setFieldValue(
        "subevent",
        addGuestFormik.values.subevent.filter(
          (value) => value != selectedOptions.value
        )
      );
    } else {
      addGuestFormik.setFieldValue("subevent", [
        ...addGuestFormik.values.subevent,
        selectedOptions.value,
      ]);
    }
  };
  const handleSubevents = (e) => {
    console.log(e.target.checked, "ss", e.target.value);
    if (e.target.checked) {
      addGuestFormik.setFieldValue("subevent", [
        ...addGuestFormik.values.subevent,
        Number(e.target.id),
      ]);
    } else {
      const v = addGuestFormik.values.subevent.filter(
        (el) => el != Number(e.target.id)
      );
      addGuestFormik.setFieldValue("subevent", [...v]);
    }
  };
  const closePanel = (e) => {
    e.preventDefault();
    closeCanvas();
  };
  console.log("addGuestFormik", addGuestFormik.errors, subEvents);
  return (
    <>
      <form onSubmit={addGuestFormik.handleSubmit}>
        <div className="addGuest-container custom-scroll-bar">
          {/* <div className="viewAllDtl-box">
            <Link className="viewAllDtl" to="">
              View all details <img src={viewEdit} />
            </Link>
          </div> */}
          <div className="radio_btn-box">
            <div className="radio_btn">
              <input
                type="radio"
                id="male"
                name="gender"
                checked={addGuestFormik.values.salutation === "Mr. "}
                onChange={() =>
                  addGuestFormik.setFieldValue("salutation", "Mr. ")
                }
              />
              <label for="male">Mr.</label>
            </div>
            <div className="radio_btn">
              <input
                type="radio"
                id="female"
                name="gender"
                checked={addGuestFormik.values.salutation === "Mrs."}
                onChange={() =>
                  addGuestFormik.setFieldValue("salutation", "Mrs.")
                }
              />
              <label for="female">Mrs.</label>
            </div>
            <div className="radio_btn">
              <input
                type="radio"
                id="grl"
                name="gender"
                checked={addGuestFormik.values.salutation === "Ms. "}
                onChange={() =>
                  addGuestFormik.setFieldValue("salutation", "Ms. ")
                }
              />
              <label for="grl">Ms.</label>
            </div>
          </div>
          <form>
            <div className="row my-4">
              <label className="form-label mb-2">
                Upload Guest List 
                <br />
                <span className="text-muted">*just selected file</span>
              </label>
              <input
                className="mt-3"
                type="file"
                accept="*"
                onChange={handleFileChange}
              />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">Guest Name</div>
                  <div className="input_field">
                    <input
                      name="name"
                      type="text"
                      className="input"
                      value={addGuestFormik.values.name}
                      onChange={addGuestFormik.handleChange}
                      placeholder="Guest Name"
                    />
                  </div>
                  {addGuestFormik.touched.name && addGuestFormik.errors.name ? (
                    <p className="error">{addGuestFormik.errors.name}</p>
                  ) : null}
                </div>
              </div>

              {/* <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Accommodation</div>
                  <div className="input_field">
                    <select name="event_type" className="input">
                      <option value="">Select Event Type</option>
                      <option value="Wedding">Wedding</option>
                      <option value="PreWedding">PreWedding</option>
                      <option value="Celebration">Celebration</option>
                    </select>
                  </div>
                  <p className="error">Please select event type</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Transportation</div>
                  <div className="input_field">
                    <select name="event_type" className="input">
                      <option value="">Select Event Type</option>
                      <option value="Wedding">Wedding</option>
                      <option value="PreWedding">PreWedding</option>
                      <option value="Celebration">Celebration</option>
                    </select>
                  </div>
                  <p className="error">Please select event type</p>
                </div>
              </div> */}
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Mobile</div>
                  <div className="input_field">
                    <input
                      name="contact_number"
                      type="tel"
                      className="input"
                      placeholder="Add Mobile"
                      value={addGuestFormik.values.contact_number}
                      onChange={addGuestFormik.handleChange}
                      maxLength={15}
                    />
                  </div>
                  {addGuestFormik.touched.contact_number &&
                    addGuestFormik.errors.contact_number ? (
                    <p className="error">
                      {addGuestFormik.errors.contact_number}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Alt Mobile</div>
                  <div className="input_field">
                    <input
                      name="alternate_contact_number"
                      type="tel"
                      className="input"
                      placeholder="Add Alt Mobile"
                      value={addGuestFormik.values.alternate_contact_number}
                      onChange={addGuestFormik.handleChange}
                      maxLength={10}
                      nullable
                    />
                  </div>
                  { }
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">Email ID</div>
                  <div className="input_field">
                    <input
                      name="email"
                      type="email"
                      className="input"
                      placeholder="Enter Guest Email"
                      value={addGuestFormik.values.email}
                      onChange={addGuestFormik.handleChange}
                    />
                  </div>
                  {addGuestFormik.touched.email &&
                    addGuestFormik.errors.email ? (
                    <p className="error">{addGuestFormik.errors.email}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">Address</div>
                  <div className="input_field">
                    {/* <input
                      name="Address"
                      type="text"
                      className="input"
                      placeholder="Enter Address"
                      value={addGuestFormik.values.Address}
                      onChange={addGuestFormik.handleChange}
                    /> */}
                    <textarea
                      name="Address"
                      rows="3"
                      className="input"
                      placeholder="Enter Address"
                      value={addGuestFormik.values.Address}
                      onChange={addGuestFormik.handleChange}
                    />
                  </div>
                  {addGuestFormik.touched.Address &&
                    addGuestFormik.errors.Address ? (
                    <p className="error">{addGuestFormik.errors.Address}</p>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="input-box">
                    <div className="input-label">Group</div>
                    <div className="input_field">
                      <input
                        name="group"
                        type="text"
                        className="input"
                        value={addGuestFormik.values.group}
                        onChange={addGuestFormik.handleChange}
                        placeholder="Group Name"
                      />
                    </div>
                    {addGuestFormik.touched.group &&
                      addGuestFormik.errors.group ? (
                      <p className="error">{addGuestFormik.errors.group}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="input-box">
                    <div className="input-label">Sub Group</div>
                    <div className="input_field">
                      <input
                        name="subgroup"
                        type="text"
                        className="input"
                        value={addGuestFormik.values.subgroup}
                        onChange={addGuestFormik.handleChange}
                        placeholder="Subgroup Name"
                      />
                    </div>
                    {addGuestFormik.touched.subgroup &&
                      addGuestFormik.errors.subgroup ? (
                      <p className="error">{addGuestFormik.errors.subgroup}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              {eventType == "Wedding" && (
                <>
                  <div className="col-lg-12">
                    <div className="functions_ttl">Sub-Events</div>
                  </div>
                  {subEvents.map((el, i) => {
                    return (
                      <div className="col-lg-6" key={`sev-${i + 1}`}>
                        <div className="custom_checkbox mg-1">
                          <input
                            id={el.value}
                            name="subevents"
                            type="checkbox"
                            onChange={handleSubevents}
                            checked={addGuestFormik.values.subevent.includes(
                              el.value
                            )}
                          />
                          <label for={el.value}>{el.label}</label>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">Hamper Gift</div>
                  <div className="input_field">
                    <input
                      name="hampergift"
                      type="text"
                      className="input"
                      placeholder="Hampergift"
                      value={addGuestFormik.values.hampergift}
                      onChange={addGuestFormik.handleChange}
                    />
                  </div>
                  {addGuestFormik.touched.hampergift &&
                    addGuestFormik.errors.hampergift ? (
                    <p className="error">{addGuestFormik.errors.hampergift}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">Notes</div>
                  <div className="input_field">
                    <textarea
                      name="notes"
                      rows="3"
                      className="input"
                      placeholder="Notes"
                      value={addGuestFormik.values.notes}
                      onChange={addGuestFormik.handleChange}
                    />
                    {/* <input
                      name="notes"
                      type="textarea"
                      className="input"
                      placeholder="Notes"
                      value={addGuestFormik.values.notes}
                      onChange={addGuestFormik.handleChange}
                    /> */}
                  </div>
                  {addGuestFormik.touched.notes &&
                    addGuestFormik.errors.notes ? (
                    <p className="error">{addGuestFormik.errors.notes}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">To be invited for</div>
                  <div className="input_field">
                    <input
                      name="to_be_invited_for"
                      type="text"
                      className="input"
                      placeholder="To be invited for"
                      value={addGuestFormik.values.to_be_invited_for}
                      onChange={addGuestFormik.handleChange}
                    />
                  </div>
                  {addGuestFormik.touched.to_be_invited_for &&
                    addGuestFormik.errors.to_be_invited_for ? (
                    <p className="error">
                      {addGuestFormik.errors.to_be_invited_for}
                    </p>
                  ) : null}
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="custom_checkbox mg-1">
                  <input id="f2" name="f2" type="checkbox" />
                  <label for="f2"> Haldi</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="custom_checkbox mg-1">
                  <input id="f3" name="f3" type="checkbox" />
                  <label for="f3">Sangeet</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="custom_checkbox mg-1">
                  <input id="f4" name="f4" type="checkbox" />
                  <label for="f4">Mehandi</label>
                </div>
              </div> */}
              {/* <div className="col-lg-12">
                <div className="input-box">
                  <div className="input-label">Address</div>
                  <div className="input_field">
                    <select name="event_type" className="input">
                      <option value="">Select Event Type</option>
                      <option value="Wedding">Wedding</option>
                      <option value="PreWedding">PreWedding</option>
                      <option value="Celebration">Celebration</option>
                    </select>
                  </div>
                  <p className="error">Please select event type</p>
                </div>
              </div> */}
              {/* <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Accommodation</div>
                  <div className="input_field">
                    <select name="event_type" className="input">
                      <option value="">Select Event Type</option>
                      <option value="Wedding">Wedding</option>
                      <option value="PreWedding">PreWedding</option>
                      <option value="Celebration">Celebration</option>
                    </select>
                  </div>
                  <p className="error">Please select event type</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Transportation</div>
                  <div className="input_field">
                    <select name="event_type" className="input">
                      <option value="">Select Event Type</option>
                      <option value="Wedding">Wedding</option>
                      <option value="PreWedding">PreWedding</option>
                      <option value="Celebration">Celebration</option>
                    </select>
                  </div>
                  <p className="error">Please select event type</p>
                </div>
              </div> */}
              <div className="col-lg-7">
                <div className="functions_ttl">Save The Date Invite</div>
              </div>
              {/* <div className="col-lg-5">
                <div className="functions_ttl">Response</div>
              </div> */}
              <div className="col-lg-7">
                <div className="custom_checkbox-box">
                  <div className="custom_checkbox mg-1">
                    <input
                      id="f5"
                      name="f5"
                      type="checkbox"
                      checked={addGuestFormik.values.save_the_date_whatsapp}
                      onChange={(e) =>
                        addGuestFormik.setFieldValue(
                          "save_the_date_whatsapp",
                          e.target.checked
                        )
                      }
                    />
                    <label for="f5">WhatsApp</label>
                  </div>
                  <div className="custom_checkbox mg-1">
                    <input
                      id="f6"
                      name="f6"
                      type="checkbox"
                      checked={addGuestFormik.values.save_the_date_email}
                      onChange={(e) =>
                        addGuestFormik.setFieldValue(
                          "save_the_date_email",
                          e.target.checked
                        )
                      }
                    />
                    <label for="f6">Email</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-box">
                  <div className="custom_checkbox mg-1">
                    <input
                      id="f66"
                      name="f66"
                      type="checkbox"
                      checked={addGuestFormik.values.whatsapp_verified}
                      onChange={(e) =>
                        addGuestFormik.setFieldValue(
                          "whatsapp_verified",
                          e.target.checked
                        )
                      }
                    />
                    <label for="f66">whatsapp verified</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Wedding Invite</div>
                  <div className="input_field">
                    <select
                      name="wedding_invite"
                      className="input"
                      value={addGuestFormik.values.wedding_invite}
                      onChange={addGuestFormik.handleChange}
                    >
                      {/* <option value="">Select Wedding Invite</option> */}
                      <option value="Sent">Sent</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Reception Invite</div>
                  <div className="input_field">
                    <select
                      name="reception_invite"
                      className="input"
                      value={addGuestFormik.values.reception_invite}
                      onChange={addGuestFormik.handleChange}
                    >
                      {/* <option value="">Select Reception Invite</option> */}
                      <option value="Sent">Sent</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">RSVP</div>
                  <div className="input_field">
                    <select
                      name="rsvp"
                      className="input"
                      value={addGuestFormik.values.rsvp}
                      onChange={addGuestFormik.handleChange}
                    >
                      {/* <option value="">Select RSVP</option> */}
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Maybe">Maybe</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-7">
                <div className="functions_ttl">Wedding Invite</div>
              </div>
              <div className="col-lg-5">
                <div className="functions_ttl">Response</div>
              </div> */}
              {/* <div className="col-lg-7">
                <div className="custom_checkbox-box">
                  <div className="custom_checkbox mg-1">
                    <input id="f5" name="f5" type="checkbox" checked={addGuestFormik.values.save_the_date_whatsapp} onChange={(e)=>addGuestFormik.setFieldValue('save_the_date_whatsapp', e.target.checked)}/>
                    <label for="f5">WhatsApp</label>
                  </div>
                  <div className="custom_checkbox mg-1">
                    <input id="f6" name="f6" type="checkbox" checked={addGuestFormik.values.save_the_date_email} onChange={(e)=>addGuestFormik.setFieldValue('save_the_date_email', e.target.checked)}/>
                    <label for="f6">Email</label>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-5">
                <div className="custom_checkbox-box">
                  <div className="custom_checkbox mg-1">
                    <input id="f7" name="f7" type="checkbox" />
                    <label for="f7">Yes</label>
                  </div>
                  <div className="custom_checkbox mg-1">
                    <input id="f8" name="f8" type="checkbox" />
                    <label for="f8">No</label>
                  </div>
                </div>
              </div> */}
            </div>
          </form>
          <div className="btn-box frm-sbmit-grp">
            <button
              className="btn-theme outline"
              onClick={(e) => closePanel(e)}
            >
              {" "}
              Cancel
            </button>
            <button className="btn-theme bg-dark">Save</button>
          </div>
        </div>
      </form>
    </>
    // <div className="m-3">
    //   <form onSubmit={addGuestFormik.handleSubmit}>
    //     <div className="input-box">
    //       <div className="input-label">Form Type</div>
    //       <div className="input_field">
    //         <select
    //           name="type"
    //           className="input"
    //           value={type}
    //           onChange={(e) => {
    //             setType(e.target.value);
    //           }}
    //         >
    //           <option value="Csv">File (csv or pdf)</option>
    //           <option value="Form">Form</option>
    //         </select>
    //       </div>
    //     </div>
    //     {type == "Form" ? (
    //       <>
    //         <div className="row">
    //           <div className="input-box mt-3">
    //             <div className="input-label">Select Guests</div>
    //             <div className="custom-dropdown-box">
    //               <CustomDropdown
    //                 options={subEvents}
    //                 placeholder="Select options"
    //                 onChange={handleOptionChange}
    //                 selectedOptions={addGuestFormik.values.subevent}
    //                 message={"sub event selected"}
    //               />
    //             </div>
    //           </div>
    //           {addGuestFormik.touched.subevent &&
    //           addGuestFormik.errors.subevent ? (
    //             <p className="error">{addGuestFormik.errors.subevent}</p>
    //           ) : null}

    //           {/* <div className="input-box mt-3">
    //             <div className="input-label">Select Sub Event</div>
    //             <div className="input_field">
    //               <select
    //                 name="subevent"
    //                 value={addGuestFormik.values.subevent}
    //                 onChange={addGuestFormik.handleChange}
    //                 className="input"
    //               >
    //                 <option value="">Select Sub Event</option>
    //                 {subEvents.map((value) => {
    //                   return (
    //                     <option value={value.id}>
    //                       {" "}
    //                       <input type="checkbox"></input> {value.name}
    //                     </option>
    //                   );
    //                 })}
    //               </select>
    //             </div>
    //           </div>
    //           {addGuestFormik.touched.subevent &&
    //           addGuestFormik.errors.subevent ? (
    //             <p className="error">{addGuestFormik.errors.subevent}</p>
    //           ) : null} */}

    //           <div className="input-box mt-3">
    //             <div className="input-label">Select Event</div>
    //             <div className="input_field">
    //               <select
    //                 name="rsvp"
    //                 value={addGuestFormik.values.rsvp}
    //                 onChange={addGuestFormik.handleChange}
    //                 className="input"
    //               >
    //                 <option value="">Select Status</option>
    //                 <option value="Yes">Yes</option>
    //                 <option value="No">No</option>
    //                 <option value="Maybe">Maybe</option>
    //               </select>
    //             </div>
    //           </div>

    //           {/* <div className="col-lg-6">
    //           <label style={{ marginTop: "53px" }}>
    //             <input
    //               name="whatsapp_verified"
    //               type="checkbox"
    //               value={addGuestFormik.values.whatsapp_verified}
    //               onChange={addGuestFormik.handleChange}
    //             />{" "}
    //             Whatsapp Verified
    //           </label>
    //         </div> */}
    //           <div className="input-box mt-3">
    //             <div className="input-label">Guest Name</div>
    //             <div className="input_field">
    //               <input
    //                 name="name"
    //                 type="text"
    //                 className="input"
    //                 placeholder="Enter guest name"
    //                 value={addGuestFormik.values.name}
    //                 onChange={addGuestFormik.handleChange}
    //                 onBlur={addGuestFormik.handleBlur}
    //               />
    //             </div>
    //           </div>
    //           {addGuestFormik.touched.name && addGuestFormik.errors.name ? (
    //             <p className="error">{addGuestFormik.errors.name}</p>
    //           ) : null}

    //           <div className="input-box">
    //             <div className="input-box">
    //               <div className="input-label">Guest Email</div>
    //               <div className="input_field">
    //                 <input
    //                   type="text"
    //                   className="input"
    //                   placeholder="Enter email"
    //                   name="email"
    //                   value={addGuestFormik.values.email}
    //                   onChange={addGuestFormik.handleChange}
    //                 />
    //               </div>
    //             </div>
    //             {addGuestFormik.touched.email && addGuestFormik.errors.email ? (
    //               <p className="error">{addGuestFormik.errors.email}</p>
    //             ) : null}
    //           </div>
    //           <div className="row">
    //             <div className="col-lg-4">
    //               <div className="input-box">
    //                 <div className="input-label"> .</div>
    //                 <div className="input_field">
    //                   <select
    //                     name="contact_number_prefix"
    //                     value={addGuestFormik.values.contact_number_prefix}
    //                     onChange={addGuestFormik.handleChange}
    //                     className="input"
    //                   >
    //                     <option value="+91">+91</option>
    //                   </select>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-lg-8">
    //               <div className="input-box">
    //                 <div className="input-box">
    //                   <div className="input-label">Guest Number</div>
    //                   <div className="input_field">
    //                     <input
    //                       type="text"
    //                       className="input"
    //                       placeholder="Enter number"
    //                       name="contact_number"
    //                       value={addGuestFormik.values.contact_number}
    //                       onChange={(e) => {
    //                         addGuestFormik.setFieldValue(
    //                           "contact_number",
    //                           e.target.value.replace(/\D/g, "")
    //                         );
    //                       }}
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             {addGuestFormik.touched.contact_number &&
    //             addGuestFormik.errors.contact_number ? (
    //               <p className="error">
    //                 {addGuestFormik.errors.contact_number}
    //               </p>
    //             ) : null}
    //           </div>
    //           {/* <div className="input-box  mt-3">
    //           <div className="input-label">Guest Hamper Gift</div>
    //           <div className="input_field">
    //             <input
    //               name="hampergift"
    //               type="text"
    //               className="input"
    //               placeholder="Enter Hamper gift details"
    //               value={addGuestFormik.values.hampergift}
    //               onChange={addGuestFormik.handleChange}
    //               onBlur={addGuestFormik.handleBlur}
    //             />
    //           </div>
    //         </div> */}
    //           <div className="input-box">
    //             <div className="input-box">
    //               <div className="input-label">Notes</div>
    //               <div className="input_field">
    //                 <textarea
    //                   type="text"
    //                   className="input"
    //                   placeholder="Enter notes"
    //                   name="notes"
    //                   value={addGuestFormik.values.notes}
    //                   onChange={addGuestFormik.handleChange}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="d-flex about-button-center mt-3">
    //           <button type="submit" className="submit-btn active">
    //             Submit
    //           </button>
    //         </div>
    //       </>
    //     ) : type == "Csv" ? (
    //       <>
    //         <input
    //           className="mt-3"
    //           type="file"
    //           accept=".csv,.pdf,.xlsx"
    //           onChange={(e) => {
    //             setGuestListFile(e.target.files[0]);
    //             // addGuestFormik.setFieldValue("file", e.target.files[0]);
    //           }}
    //         />
    //         <div className="d-flex about-button-center mt-3">
    //           <button
    //             type="button"
    //             className="submit-btn active"
    //             onClick={() => {
    //               uploadGuestListFile();
    //             }}
    //           >
    //             Submit
    //           </button>
    //         </div>
    //       </>
    //     ) : (
    //       <></>
    //     )}
    //   </form>
    // </div>
  );
};

export default GuestAdd;
