import axios from "axios";

export const requestWithoutToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  switch (method) {
    case "GET":
      request = await axios.get(url);
      break;
    case "POST":
      request = await axios.post(url, body);
      break;
    default:
      break;
  }

  return request;
};
export const callStripeWebhook = async (sessionId, success) => {
  try {
    let url = process.env.REACT_APP_API_ENDPOINT + 'api/webhook/stripe/';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ session_id: sessionId, success }),
    });
    return await response.json();
  } catch (error) {
    console.error('Error calling webhook:', error);
    throw error;
  }
};


export const requestWithToken = async (method, url, body, responseType) => {
  try {
    // Format URL properly
    const fullUrl = process.env.REACT_APP_API_ENDPOINT + (url.startsWith('/') ? url.substring(1) : url);
    console.log(`Making ${method} request to:`, fullUrl);
    console.log('Request body:', body);

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
    };

    if (responseType) {
      headers.responseType = responseType;
    }

    let response;
    switch (method) {
      case "GET":
        response = await axios.get(fullUrl, { headers });
        break;
      case "POST":
        response = await axios.post(fullUrl, body, { headers });
        break;
      case "PUT":
        response = await axios.put(fullUrl, body, { headers });
        break;
      case "DELETE":
        response = await axios.delete(fullUrl, {
          data: body,
          headers,
        });
        break;
      default:
        throw new Error(`Unsupported method: ${method}`);
    }

    console.log('Response:', response);
    return response;
  } catch (error) {
    console.error("Request error:", error);
    if (error.response?.status === 401) {
      window.location.href = '/login';
    }
    throw error;
  }
};

