import * as yup from "yup";
export const guestValidationSchema = yup.object({
  name: yup
    .string()
    .required("Enter guest name")
    .matches(/^[a-z A-Z]+$/, "Please enter valid name"),
  email: yup
    .string()
    .required("Enter email address")
    .matches(/\S+@\S+\.\S+/, "Please enter valid email"),
  contact_number: yup
    .string()
    .required("Enter contact no.")
    .matches(/^\d{10,15}$/, "Phone number must be between 10 and 15 digits"),
  alternate_contact_number: yup
    .string()
    .nullable()
    .matches(/^\d{10,15}$/, "Phone number must be between 10 and 15 digits"),
});



