import { useState, useEffect } from "react";
import "../styles/style.css";
import {
  fetchActiveEvents,
  fetchAllEventCounts,
  fetchPastEvents,
} from "../action/events";
import moment from "moment";
import PrivacyPolicy from "../components/privacy-policy";

//images
import greenDotIcon from "../assets/images/green-dot.svg";
import orangeDotIcon from "../assets/images/orange-dot.svg";
import dArrow from "../assets/images/d-arrow.svg";
import calendarIcon from "../assets/images/calendor.svg";
import locationIcon from "../assets/images/location.svg";
import plusSmallIcon from "../assets/images/plus-small.svg";
import clockIcon from "../assets/images/clock.svg";
import SliderCard from "../components/eventCards/sliderCard.js";
import { useDispatch, useSelector } from "react-redux";
import { changeHeaderState } from "../reducer/slice/header";
import { Link } from "react-router-dom";
import AiChatBox from "../components/aiChatBox/aiChatBox";

export function DashboardLayout() {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.changeSearchText.value ?? "");

  const [allEventCounts, setAllEventCounts] = useState({});
  const [activeEvents, setActiveEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [selectEventType, setSelectedEventType] = useState("");

  const [allEventBox] = useState([
    { value: "total_event", label: "Total Events", eventType: "" },
    { value: "wedding_event", label: "Wedding Events", eventType: "Wedding" },
    {
      value: "prewedding_event",
      label: "PreWedding Events",
      eventType: "PreWedding",
    },
    {
      value: "celebration_event",
      label: "Celebration Events",
      eventType: "Celebration",
    },
  ]);

  const getAllEventsCount = async () => {
    const res = await fetchAllEventCounts(searchText);
    if (res?.status) {
      setAllEventCounts(res.data);
    }
  };

  const getActiveEvents = async () => {
    const res = await fetchActiveEvents(selectEventType, 1, 3, searchText);
    if (res?.status) {
      setActiveEvents(res.data.objects);
    }
  };

  const getPastEvents = async () => {
    const res = await fetchPastEvents(selectEventType, 1, 3, searchText);
    if (res?.status) {
      setPastEvents(res.data.objects);
    }
  };

  useEffect(() => {
    getActiveEvents();
    getPastEvents();
  }, [selectEventType]);

  useEffect(() => {
    getAllEventsCount();
    getActiveEvents();
    getPastEvents();
  }, [searchText]);

  useEffect(() => {
    dispatch(
      changeHeaderState({
        showDefault: true,
        headerTitle: "",
        navigationList: [],
      })
    );
  }, []);

  // const fbLoginCallback = (response) => {
  //   if (response.authResponse) {
  //     const code = response.authResponse.code;
  //     console.log({ code });
  //     // The returned code must be transmitted to your backend first and then
  //     // perform a server-to-server call from there to our servers for an access token.
  //   }
  //   document.getElementById("sdk-response").textContent = JSON.stringify(
  //     response,
  //     null,
  //     2
  //   );
  // };

  // function launchWhatsAppSignup() {
  //   // Launch Facebook login
  //   window.FB.login(fbLoginCallback, {
  //     config_id: "1656357241790927", // configuration ID goes here
  //     response_type: "code", // must be set to 'code' for System User access token
  //     override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
  //     extras: {
  //       setup: {},
  //       featureType: "",
  //       sessionInfoVersion: "2",
  //     },
  //   });
   
  return (
<div className="content-container ">
<div className="col-md-9">    
      <div className="content-container-left">
        <div className="section-row mb-3">
          <div className="heading-row">
            <h2 className="item-heading1">Events Overview</h2>
            {/* <button onClick={launchWhatsAppSignup} className="btn-theme">
              <span>WhatsApp</span>
            </button> */}
          </div>

          <div className="row">
            {allEventCounts ? (
              allEventBox?.map((value) => {
                return (
                  <div
                    className="col-md-3 g-3"
                    onClick={() => {
                      setSelectedEventType(value.eventType);
                    }}
                  >
                    <div
                      className={`card-custom card1 ${value.eventType == selectEventType ? "active" : ""
                        }`}
                    >
                      <h3 className="card-title">{value.label}</h3>
                      <div className="card1-infobx">
                        <div className="card1-infobx-left">
                          <div className="circlebx">
                            <div className="circle-pro">
                              <span>
                                {allEventCounts[value.value]?.future +
                                  allEventCounts[value.value]?.past}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card1-infobx-right">
                          <div className="inforow">
                            <img src={greenDotIcon} alt="" />
                            <div className="ifottx">
                              {allEventCounts[value.value]?.future} In-Progress
                            </div>
                          </div>
                          <div className="inforow">
                            <img src={orangeDotIcon} alt="" />
                            <div className="ifottx">
                              {allEventCounts[value.value]?.past} Completed
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>

        {activeEvents.length ? (
          <div className="section-row">
            <div className="heading-row">
              <h2 className="item-heading1">Active Events</h2>
              <Link to="/events" className="viewall">
                View All
              </Link>
            </div>
            <div className="row">
              {
                <SliderCard activeEvents={activeEvents} />
              }
            </div>
          </div>
        ) : (
          <></>
        )}

        {pastEvents.length ? (
          <div className="section-row mt-3">
            <div className="heading-row">
              <h2 className="item-heading1">Past Events</h2>
              <Link to="/events" className="viewall">
                View All
              </Link>
            </div>
            <div className="databox">
              {pastEvents.map((value, index) => {
                return (
                  <div className="card-custom">
                    <div className="data-row">
                      <table className="data-tbl">
                        <tr>
                          <td>
                            <span className="datasr">{index + 1}</span>
                          </td>
                          <td>
                            <span className="data-ttl">{value.name}</span>
                          </td>
                          <td>
                            <button className="dtat-btn">
                              {value.event_type}
                            </button>
                          </td>
                          <td>
                            <span className="data-itm">
                              <img src={locationIcon} alt="" /> {value.location}
                            </span>
                          </td>
                          <td>
                            <span className="data-itm">
                              <img src={calendarIcon} alt="" />{" "}
                              {moment(value.date).format("DD MMM, YYYY")}
                            </span>
                          </td>
                          <td>
                            <Link to={`/event-details/${value.id}`} className="viewall">
                              <button className="dtat-btn-circle">
                                <img src={dArrow} alt="" />
                              </button>
                            </Link>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="content-container-right">
        {/* <div className="card-custom card4">
          <div className="card4-head">
            <h2 className="heading-with-badge">
              <div>
                Important Tasks <span className="theme-badge red up">6</span>
              </div>
            </h2>
            <button className="btn-theme circle plus">
              <img src={plusSmallIcon} alt="" />
            </button>
          </div>
          <div className="card4-body">
            <ul className="card4ul custom-scroll-bar">
              <li>
                <p className="text1">Book pending flight tickets</p>
                <p className="text2">Simran & Raj | Wedding</p>
                <p className="postdate">
                  <span className="cloicon">
                    <img src={clockIcon} alt="" />
                  </span>{" "}
                  2 hrs ago
                </p>
              </li>
              <li>
                <p className="text1">Book pending flight tickets</p>
                <p className="text2">Simran & Raj | Wedding</p>
                <p className="postdate">
                  <span class="cloicon">
                    <img src={clockIcon} alt="" />
                  </span>{" "}
                  2 hrs ago
                </p>
              </li>
              <li>
                <p class="text1">Book pending flight tickets</p>
                <p class="text2">Simran & Raj | Wedding</p>
                <p class="postdate">
                  <span class="cloicon">
                    <img src={clockIcon} alt="" />
                  </span>{" "}
                  2 hrs ago
                </p>
              </li>
              <li>
                <p class="text1">Book pending flight tickets</p>
                <p class="text2">Simran & Raj | Wedding</p>
                <p class="postdate">
                  <span class="cloicon">
                    <img src={clockIcon} alt="" />
                  </span>{" "}
                  2 hrs ago
                </p>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
    </div>
  );
}


export default DashboardLayout;
