import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createEvent, getCompanyDetails } from "../../action/events";
import { useNavigate } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import WeddingForm from "../../components/eventForms/weddingForm";
import PreWeddingForm from "../../components/eventForms/preWeddingForm";
import CelebrationForm from "../../components/eventForms/celebrationForm";

import squar from "../../assets/images/squar.svg";
import EventDetailForm from "../../components/eventForms/eventDetailForm";
import SubEventForm from "../../components/eventForms/subEventForm";
import GuestAddForm from "../../components/eventForms/guestAddForm";
import { addGuest } from "../../action/guests";
import { createSubEvent } from "../../action/subEvents";
import { uploadFile } from "../../action/upload";
import Swal from "sweetalert2";
import { getCoinRequirement } from "../../action/coinSettings";
const EventAdd = () => {
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState(0);
  const [isWedding, setIsWedding] = useState(false);

  const [eventUrlType, setEventUrlType] = useState("");
  const [eventDetail, setEventDetails] = useState({});
  const [subEventDetails, setSubEventDetails] = useState([]);
  const [guestDetails, setGuestDetails] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [showUploadSheet, setShowUploadSheet] = useState(false);

  useEffect(() => {
    const checkCoinRequirements = async () => {
      try {
        const [weddingCoins, preWeddingCoins, celebrationCoins, subEventCoins] = await Promise.all([
          getCoinRequirement("Wedding"),
          getCoinRequirement("PreWedding"), 
          getCoinRequirement("Celebration"),
          getCoinRequirement("SubEvent")
        ]);

        if (!weddingCoins.status || !preWeddingCoins.status || !celebrationCoins.status || !subEventCoins.status) {
          throw new Error("Failed to fetch coin requirements");
        }


        const priceText = `
          <div style="text-align: left; margin-top: 10px;">
            <p><strong>Event Creation Costs:</strong></p>
            <ul style="list-style: none; padding-left: 0;">
              <li>• Wedding Event: ${weddingCoins.data?.data?.required_coins || 1} coins</li>
              <li>• Pre-Wedding Event: ${preWeddingCoins.data?.data?.required_coins || 1} coins</li>
              <li>• Celebration Event: ${celebrationCoins.data?.data?.required_coins || 1} coins</li>
              <li>• Sub Event: ${subEventCoins.data?.data?.required_coins || 1} coins per sub-event</li>
            </ul>
            <p style="margin-top: 10px; color: #666;">
              <i>Note: If you don't have enough coins, please visit the Manage Subscription page.</i>
            </p>
          </div>
        `;

        await Swal.fire({
          title: "Event Creation Costs",
          html: priceText,
          icon: "info",
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: "Continue",
          denyButtonText: "Go to Subscription",
          cancelButtonText: "Cancel",
          reverseButtons: true,
          customClass: {
            popup: 'swal-wide',
            content: 'text-left',
            confirmButton: 'swal2-confirm',
            denyButton: 'swal2-deny',
            cancelButton: 'swal2-cancel'
          }
        }).then((result) => {
          if (result.isDenied) {
            navigate("/subscription");
          } else if (result.isDismissed) {
            navigate("/");
          }
        });

      } catch (error) {
        console.error("Error checking coin requirements:", error);
        Swal.fire({
          title: "Error",
          text: "Unable to fetch coin requirements. Please try again later.",
          icon: "error",
          confirmButtonText: "OK"
        }).then(() => {
          navigate("/");
        });
      } finally {
      }
    };

    checkCoinRequirements();
  }, [navigate]);

  const finalApi = () => {
    addEvent(eventDetail, eventUrlType);
  };

  const addEvent = async (eventDetail, url) => {
    
    const res = await createEvent(eventDetail, url);
    if (res.status) {
      if (eventDetail.event_type != "Wedding") {
        let allGuestDetails = [...guestDetails];
        guestDetails.map((value, index1) => {
          allGuestDetails[index1].company = eventDetail.company;
          allGuestDetails[index1].event = res.data.id;
        });

        setTimeout(() => {
          if (selectedFile) {
            uploadGuestListFile(res.data.id, selectedFile);
          } else {
            createGuest(res.data.id, allGuestDetails);
          }
        }, 3000);
      } else {
        addSubEvents(res.data.id, subEventDetails);
      }
    } else {
      showNotification(res.message, "danger");
    }
  };

  const addSubEvents = async (eventId, value) => {
    let allGuestDetails = [...guestDetails];
    if (value.length) {
      const res = await createSubEvent(eventId, value);
      if (res.status) {
        res.data.map((mainSub) => {
          guestDetails.map((value, index1) => {
            allGuestDetails[index1].company = eventDetail.company;
            allGuestDetails[index1].event = eventId;

            value.subevent.map((sub, index2) => {
              if (mainSub.name == sub) {
                allGuestDetails[index1].subevent[index2] = mainSub.id;
              }
            });
          });
        });
      }
    }
    console.log("JDIF", allGuestDetails);
    setTimeout(() => {
      if (selectedFile) {
        uploadGuestListFile(eventId, selectedFile);
      } else {
        createGuest(eventId, allGuestDetails);
      }
    }, 3000);
  };

  const createGuest = async (eventId, payload) => {
    const res = await addGuest(payload, eventId);

    if (res.status) {
      showNotification("Event Created Successfully");
      navigate("/");
    } else {
      showNotification(res.message, "danger");
    }
  };

  const uploadGuestListFile = async (eventId, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("event_id", eventId);
    const res = await uploadFile(formData);
    console.log("🚀 ~ uploadGuestListFile ~ res:", res)
    if (res.status) {
      showNotification("Event Created Successfully");
      navigate("/");
    }
  };

  useEffect(() => {
    if (guestDetails.length || selectedFile) {
      finalApi();
    }
  }, [guestDetails, selectedFile]);

  const handleStepChange = (step, data, url) => {
    if (data && url) {
      setEventDetails(data);
      setEventUrlType(url);
    }
    setCurrentStatus(step);
  };

  return (
    <div className="content-body">
      <div className="dashboard_head">
        <div className="dashboard_head-left">
          <h1 className="heading1">Create an Event</h1>
        </div>
      </div>
      <div className="tab-bar-sec">
        <ul className="tab-bar">
          <li className={currentStatus === 0 ? "active" : ""}>
            Event Details
          </li>
          {isWedding && (
            <li className={currentStatus === 1 ? "active" : ""}>
              Function Details
            </li>
          )}
          <li className={currentStatus === 2 ? "active" : ""}>
            Guest Details
          </li>
        </ul>
      </div>
      <div className="form_data-content">
        <div style={{ display: currentStatus === 0 ? "block" : "none" }}>
          <EventDetailForm
            setIsWedding={setIsWedding}
            changeStep={handleStepChange}
          />
        </div>
        {isWedding && (
          <div style={{ display: currentStatus === 1 ? "block" : "none" }}>
            <SubEventForm
              changeStep={(step, data, type) => {
                setCurrentStatus(step);
                if (type === 1) {
                  setShowUploadSheet(true);
                } else if (data) {
                  setSubEventDetails(data);
                }
              }}
            />
          </div>
        )}
        <div style={{ display: currentStatus === 2 ? "block" : "none" }}>
          <GuestAddForm
            isWedding={isWedding}
            showUploadSheet={showUploadSheet}
            mySubEvents={subEventDetails}
            changeStep={(step, data, type) => {
              setCurrentStatus(step);
              if (data && type) {
                if (type === 1) {
                  setSelectedFile(data);
                } else {
                  setGuestDetails(data);
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventAdd;
