// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 20px auto;
  height: calc(100vh - 100px);
  max-height: 800px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  overflow: hidden;
} `, "",{"version":3,"sources":["webpack://./src/components/whatsapp/ChatContainer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,2BAA2B;EAC3B,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,qCAAqC;EACrC,gBAAgB;AAClB","sourcesContent":[".chat-container {\r\n  display: flex;\r\n  width: 100%;\r\n  max-width: 1400px;\r\n  margin: 20px auto;\r\n  height: calc(100vh - 100px);\r\n  max-height: 800px;\r\n  background-color: #ffffff;\r\n  border-radius: 8px;\r\n  box-shadow: 0 2px 5px rgba(0,0,0,0.1);\r\n  overflow: hidden;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
